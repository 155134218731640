import React from "react";
import { SvgProps } from "react-native-svg";

//SVGs
import BTNDisclaimer from "./btn-disclaimer.svg";
import BTNFaq from "./btn-faq.svg";
import BTNLogout from "./btn-logout.svg";
import BTNPrivacy from "./btn-privacy-policy.svg";
import BTNShare from "./btn-share.svg";
import BTNTermsCondition from "./btn-terms-condition.svg";
import BTNTier from "./btn-tier-level.svg";
import TSettings from "./t-settings.svg";
import BGSettings from "./bg-settings.svg";
import BGSound from "./bg-sound.svg";
import User from "./user.svg";

interface IconProps extends SvgProps {
  name: "btn-disclaimer" | "btn-faq" | "btn-logout" | "btn-privacy-policy" | "bg-sound" | "user" |
    "btn-share" | "btn-terms-condition" | "btn-tier-level" | "t-settings" | "bg-settings";
  size?: number;
  stroke?: string;
  fill?: string;
  isActive?: boolean;
}

const SVGSettings = (props: IconProps) => {
  const { name, width, height } = props;
  switch (name) {
  case "btn-disclaimer":
    return <BTNDisclaimer width={width} height={height} {...props} />;
  case "btn-faq":
    return <BTNFaq width={width} height={height} {...props} />;
  case "btn-logout":
    return <BTNLogout width={width} height={height} {...props} />;
  case "btn-privacy-policy":
    return <BTNPrivacy width={width} height={height} {...props} />;
  case "btn-share":
    return <BTNShare width={width} height={height} {...props} />;
  case "btn-terms-condition":
    return <BTNTermsCondition width={width} height={height} {...props} />;
  case "btn-tier-level":
    return <BTNTier width={width} height={height} {...props} />;
  case "t-settings":
    return <TSettings width={width} height={height} {...props} />;
  case "bg-settings":
    return <BGSettings width={width} height={height} {...props} />;
  case "bg-sound":
    return <BGSound width={width} height={height} {...props} />;
  case "user":
    return <User width={width} height={height} {...props} />;
  default:
    return null;
  }
};

export default React.memo(SVGSettings);
