import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { useAuthService } from "@src/ducks/hooks";
import { Loading01 } from "components/loading";
import { TextInput01 } from "components/textinput";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Footer from "./Footer";
import useStyles from "./styles.css";

interface ELoginProps {
  email?: string;
  password?: string;
}

const Login = () => {
  const styles = useStyles();
  const [email, setEmail] = useState("");
  const navigation = useNavigation<any>();
  const [password, setPassword] = useState("");
  const [error, setError] = useState<ELoginProps>({});
  const { baseWidth, maxHeight } = React.useContext(DimensionContext);
  const { onSignin, isLoading, onResetLoading } = useAuthService();

  const onLogin = () => {
    const Err: ELoginProps = {};
    if (!email) {
      Err.email = "Required";
    } else if (!password) {
      Err.password = "Required";
    }

    setError(Err);

    if (_.isEmpty(Err)) {
      onSignin({username: email, password});
    }
  };

  useEffect(() => {
    onResetLoading();
  }, []);
  
  useEffect(() => {
    setError({});
  }, [email, password]);

  return (
    <ImageBackground
      source={{uri: images["gamezone-bg"]}}
      style={[styles.container]}
      resizeMode="stretch"
    >
      <View style={[styles.bodyStyle, { height: maxHeight * 0.5}]}>
        <Image
          source={{uri: images.bg_transparent}}
          style={styles.imageBorderStyle}
          resizeMode="stretch"
        />
        <Image
          source={{uri: images["gamezone-logo-horizontal"]}}
          style={styles.smallLogoContainer}
          resizeMode="stretch"
        />
        <View style={styles.viewLoginStyle}>
          <Image
            source={{uri: images["text-login"]}}
            style={styles.loginTextStyle}
            resizeMode="contain"
          />
        </View>
        <View style={styles.v_inputs}>
          <TextInput01
            value={email}
            placeholder="Email"
            error={error?.email}
            onChangeText={setEmail}
            disableFullscreenUI={true}
            keyboardType="email-address"
            inputStyle={[styles.textAlign, { fontSize: baseWidth * 0.012}]}
            containerStyle={[styles.ti_email]}
          />
          <TextInput01
            hasPassword
            value={password}
            placeholder="Password"
            secureTextEntry={true}
            error={error?.password}
            disableFullscreenUI={true}
            onChangeText={setPassword}
            inputStyle={[styles.textAlign, { fontSize: baseWidth * 0.012}]}
            containerStyle={[styles.ti_email]}
          />
          <View style={styles.forgotWrapper}>
            <TouchableOpacity
              onPress={() => navigation.navigate("SignUp")}
              style={styles.btnForgot} >
              <Text style={[styles.txtSignUp, { fontSize: baseWidth * 0.011}]}>Don’t have an account?{" "}
                <Text style={styles.txtSignUp_bold}>SIGN UP HERE</Text></Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => navigation.navigate("Forgot")}
              style={styles.btnForgot} >
              <Text style={[styles.txtForgot, { fontSize: baseWidth * 0.011}]}>forgot password?</Text>
            </TouchableOpacity>
          </View>
        </View>
        <Footer onPress={onLogin} baseWidth={baseWidth} />
        <Loading01 isLoading={isLoading} customStyle={{borderRadius: baseWidth * 0.025}} />
      </View>
    </ImageBackground>
  );
};

export default Login;
