import images from "@assets/images";

const Avatars = [
  {
    id: 1,
    body: images.Avatar_boy_1,
  },
  {
    id: 2,
    body: images.Avatar_boy_2,
  },
  {
    id: 3,
    body: images.Avatar_boy_3,
  },
  {
    id: 4,
    body: images.Avatar_boy_4,
  },
  {
    id: 5,
    body: images.Avatar_boy_5,
  },
  {
    id: 6,
    body: images.Avatar_girl_1,
  },
  {
    id: 7,
    body: images.Avatar_girl_2,
  },
  {
    id: 8,
    body: images.Avatar_girl_3,
  },
  {
    id: 9,
    body: images.Avatar_girl_4,
  },
  {
    id: 10,
    body: images.Avatar_girl_5,
  },
];

export default Avatars;
