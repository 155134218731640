import Icon from "@expo/vector-icons/MaterialIcons";
import { colors } from "@src/theme";
import React, { useRef, useState } from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { ms } from "react-native-size-matters";
import TextDropDown from "./TextDropDown";
import { InputProps } from "./types";

const TextInput02 = (props: InputProps) => {
  const {
    error,
    containerStyle,
    inputStyle,
    placeholder,
    onChangeText,
    options,
    value,
  } = props;
  const viewRef = useRef<any>();
  const [measure, setMesasure] = useState({});
  const [isFocus, setFocus] = useState(false);

  const updatePosition = () => {
    setFocus(true);
  };

  const onLayout = (e: any) => {
    const { left, top, width, height } = e.nativeEvent.layout;
    setMesasure({ x: top, y: left, w: width, h: height });
  };

  return (
    <TouchableOpacity
      ref={viewRef}
      onLayout={onLayout}
      activeOpacity={1}
      onPress={updatePosition}
      style={[styles.container, containerStyle, error && styles.errorStyle]}
    >
      <Text style={[styles.input, inputStyle]}>{value || placeholder}</Text>
      <Icon name="arrow-drop-down" size={15} color="black" />
      {!!error && <Text style={styles.textError}>Required</Text>}

      <TextDropDown
        visible={isFocus}
        viewRef={viewRef}
        measure={measure}
        options={options}
        onSelected={onChangeText}
        onClose={() => setFocus(false)}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "60%",
    height: "15%",
    borderRadius: 5,
    paddingRight: 6,
    marginBottom: ms(15),
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.white,
  },
  errorStyle: {
    borderWidth: 2,
    borderColor: colors.red,
  },
  textError: {
    right: 10,
    fontSize: 10,
    color: colors.red,
    position: "absolute",
    alignSelf: "center",
    fontFamily: "Roboto",
  },
  input: {
    flex: 1,
    fontSize: 13,
    paddingLeft: 10,
    color: colors.black,
    fontFamily: "Roboto",
  },
});

export default React.memo(TextInput02);
