import images from "@assets/images";
import React from "react";
import { Image, TouchableWithoutFeedback, View } from "react-native";
import useStyles from "./styles.css";

interface AvatarItemProps {
  item: any;
  baseWidth: number;
  isActive?: boolean;
  onPress?: () => void;
}

const AvatarItem = (props: AvatarItemProps) => {
  const styles = useStyles();
  const { item, onPress, isActive } = props;

  return (
    <View style={[styles.slotContainerStyle, { width: props.baseWidth * 0.18, height: props.baseWidth * 0.3599 }]}>
      {isActive && <Image
        source={{uri: images.avatar_frame}}
        resizeMode="stretch"
        style={styles.i_active_shine}
      />}
      <TouchableWithoutFeedback onPress={onPress}>       
        <Image
          source={item.body}
          resizeMode="stretch"
          style={styles.slotImageStyle}
        />
      </TouchableWithoutFeedback>
    </View>
  );
};

export default AvatarItem;
