import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import React from "react";
import { Image, View } from "react-native";
import Avatar from "./Avatar";
import Details from "./Details";
import Tier from "./Tier";
import useStyles from "./styles.css";

const Profile = () => {
  const styles = useStyles();
  const { baseWidth } = React.useContext(DimensionContext);

  return (
    <View style={[styles.container]}>
      <View style={[styles.bodyStyle, { height: baseWidth * 0.4783}]}>
        <Image
          style={styles.i_bg}
          source={{uri: images.bg_transparent}}
          resizeMode="stretch"
        />
        <View style={styles.v_bg}>
          <Avatar />
          <Details />
          <Tier />
        </View>
      </View>
    </View>
  );
};

export default Profile;
