import { useAppSelector } from "@src/ducks/ducksHook";
import { useAuthService } from "@src/ducks/hooks";
import { selectedBackgroundSound } from "@src/ducks/slices/auth.slice";
import { colors } from "@src/theme";
import React, { useEffect, useRef, useState } from "react";
import { Animated, StyleSheet, TouchableWithoutFeedback } from "react-native";

const SwitchItem = () => {
  const [width, setWidth] = useState(0);
  const [circleWidth, setCircleWidth] = useState(14);
  const { onBackgrounsSoundState } = useAuthService();
  const backgroundSound = useAppSelector(selectedBackgroundSound);
  const translateX = useRef(new Animated.Value(0)).current;

  const onPress = () => {
    onBackgrounsSoundState(!backgroundSound);
  };

  const backgroundColor = translateX.interpolate({
    inputRange: [-circleWidth, 0],
    outputRange: [colors.red, colors.green1],
  });

  const onLayout = (e: any) => {
    setWidth(e.nativeEvent.layout.width);
  };

  const circleLayout = (e: any) => {
    setCircleWidth(e.nativeEvent.layout.width);
  };

  const animateSound = () => {
    if (!width) {
      return null;
    }

    const toValue = backgroundSound ? 0 : -(width - circleWidth / 1.2);

    Animated.timing(translateX, {
      toValue: toValue,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  useEffect(() => {
    animateSound();
  }, [backgroundSound, width]);

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <Animated.View onLayout={onLayout} style={[styles.container]}>
        <Animated.View
          onLayout={circleLayout}
          style={[
            styles.circleStyle,
            { backgroundColor, transform: [{ translateX }] },
          ]}
        />
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "35%",
    height: "50%",
    borderRadius: 25,
    alignItems: "flex-end",
    justifyContent: "center",
    alignSelf: "flex-end",
    backgroundColor: "rgba(255,255,255,0.6)",
  },
  circleStyle: {
    width: "50%",
    height: "120%",
    borderRadius: 100,
    position: "absolute",
    backgroundColor: colors.green1,
  },
});

export default SwitchItem;
