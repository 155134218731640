import images from "@assets/images";
import SVGSettings from "@assets/svg/settings";
import { useAuthService, useSettingsService } from "@src/ducks/hooks";
import { ImageButton05 } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import Alert from "utils/alert";
import useStyles from "./styles.css";

const Logout = ({ baseWidth, baseHeight }: any) => {
  const styles = useStyles();
  const {onLogout} = useAuthService();
  const { onDeactivatingRequest } = useSettingsService();

  const onDeactivate = () => {
    Alert(
      "Deactivate Account",
      "Are you sure you want to deactivate your account?",
      [
        {
          text: "Okay",
          onPress: () => onDeactivatingRequest(),
        },
      ],
      { cancelable: false }
    );
  };

  return (
    <View style={styles.v_logout}>
      <ImageButton05 scaleSize={0.98} onPress={onLogout} 
        style={{width: baseWidth * 0.3631, height: baseHeight * 0.12}} >
        <Image
          source={{uri: images["btn-big"]}}
          style={styles.i_logout}
          resizeMode="stretch"
        />
        <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%", height: "93%"}}>
          <Text selectable={false} style={[styles.t_logout, { fontSize: baseWidth * 0.04}]}>LOG OUT</Text>
          <SVGSettings name="user" width={baseWidth * 0.042} height={baseHeight * 0.042} />
        </View>
      </ImageButton05>
      {/* <Text
        suppressHighlighting
        onPress={onDeactivate}
        style={[styles.txt_deactivate, { fontSize: baseWidth * 0.03}]}
      >
        Deactivate Account
      </Text> */}
    </View>
  );
};

export default React.memo(Logout);
