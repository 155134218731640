import React from "react";
import { useNavigation } from "@react-navigation/native";
import Settings from "screens/settings/Settings";
import { Scale } from "utils/animation";
import useStyles from "./styles.css";
import { View } from "react-native";

export default () => {
  const styles = useStyles();
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <Scale style={styles.container}>
        <Settings
          style={styles.v_settings}
          onBack={() => navigation.goBack()}
        />
      </Scale>
    </View>
  );
};
