import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedProfileStatus } from "@src/ducks/slices/settings.slice";
import React, { useContext } from "react";
import { StyleSheet } from "react-native";
import { Height } from "utils/animation";
import { AvatarContext } from "./AvatarProvider";
import Tier from "./Tier";
import AvatarList from "./avatarlist";
import Profile from "./profile";

const Avatars = () => {
  const { showScreen } = useContext(AvatarContext);
  const hasProfileOpen =  useAppSelector(selectedProfileStatus);

  return (
    <Height style={styles.container} toValue={hasProfileOpen ? 1 : 0}>
      {(showScreen === "avatar" && <AvatarList />) ||
        (showScreen === "tier" && <Tier />) || <Profile />}
    </Height>
  );
};

const styles = StyleSheet.create({
  container: {
    top: 0,
    zIndex: 5,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "absolute",
  },
});

export default Avatars;
