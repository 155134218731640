import { colors } from "@src/theme";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "60%",
      height: "15%",
      borderRadius: 5,
      flexDirection: "row",
      backgroundColor: colors.white,
    },
    errorStyle: {
      borderWidth: 2,
      borderColor: colors.red,
    },
    textError: {
      right: 10,
      zIndex: 2,
      fontSize: 10,
      color: colors.red,
      position: "absolute",
      alignSelf: "center",
      backgroundColor: "white",
      fontFamily: "Roboto",
    },
    input: {
      flex: 1,
      paddingLeft: 10,
      color: colors.black,
      fontFamily: "Roboto",
    },
    btn_icon: { position: "absolute", width: 24, height: 24, right: 10, alignSelf: "center", },
  }
);

export default useStyles;
