import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 6,
      alignItems: "center",
      justifyContent: "center",
      ...StyleSheet.absoluteFillObject,
      backgroundColor: colors.translucent,
    },
    bodyStyle: {
      width: "70%",
      height: "80%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    v_center: {
      width: "96%",
      height: "92%",
      top: "-1%",
      left: "-0.5%",
      alignItems: "center",
    },
    btn_close: {
      width: "5%",
      height: "10%",
      top: "7%",
      right: "6%",
      zIndex: 6,
      position: "absolute",
    },
    viewForgotStyle: {
      marginTop: "2%",
      width: "50%",
      height: "20%",
      alignItems: "center",
      justifyContent: "center",
    },
    forgotTextStyle: { width: "100%", height: "50%" },
    t_title: {fontFamily: "Roboto-Bold", color: colors.white, textAlign: "center"},
    viewEmail: {
      right: "1%",
      width: "93%",
      height: "57%",
      marginTop: "1%",
      alignItems: "center",
      paddingHorizontal: "7%",
    },
    t_description: {fontFamily: "Roboto", color: colors.white, textAlign: "center"},
    v_button: {
      width: "90%",
      height: "17%",
      alignItems: "center",
    },
    btnSubmit: { width: "18%", height: "70%" },
  },
);

export default useStyles;
