import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import React from "react";
import { Image, View } from "react-native";
import SilverCoins from "./silver-coins";
import useStyles from "./styles.css";

const SilverBar = () => {
  const styles = useStyles();
  const { baseWidth, hudHeight } = React.useContext(DimensionContext);

  return (
    <View style={[styles.container, { width: baseWidth * 0.1927, height: hudHeight * 0.6197} ]}>
      <Image
        resizeMode="stretch"
        style={styles.silverBar}
        source={{uri: images.silver_bar}}
      />
      <SilverCoins baseHeight={hudHeight} />
    </View>
  );
};

export default SilverBar;
