import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "60%",
      height: "80%",
      alignItems: "center",
      justifyContent: "center",
    },
    silverBar: {
      width: "100%",
      height: "100%",
    },
    silverText: {
      right: "14%",
      marginTop: "5%",
      color: "white",
      fontSize: 10,
      position: "absolute",
      fontFamily: "Roboto-Medium",
    },
  },
);

export default useStyles;
