import React, { useRef, useState } from "react";
import { TextInput, Text, Pressable, Keyboard } from "react-native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { InputProps } from "./types";
import useStyles from "./styles.css";
import colors from "theme/colors";

const TextInput01 = (props: InputProps) => {
  const {
    error,
    value,
    hasPassword,
    inputStyle,
    placeholder,
    onChangeText,
    keyboardType,
    containerStyle,
  } = props;
  const styles = useStyles();
  const txtRef = useRef<any>();
  const [isFocus, setFocus] = useState(false);
  const [showPass, setShowPass] = useState(!!hasPassword);

  return (
    <Pressable
      onPress={() => txtRef.current.focus()}
      onFocus={() => txtRef.current.focus()}
      style={[styles.container, containerStyle, error && styles.errorStyle]}
    >
      <TextInput
        ref={txtRef}
        value={value}
        autoCorrect={false}
        autoCapitalize="none"
        keyboardType={keyboardType}
        onChangeText={onChangeText}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        style={[styles.input, inputStyle]}
        onSubmitEditing={Keyboard.dismiss}
        secureTextEntry={showPass}
        placeholder={isFocus ? "" : placeholder}
      />
      {(!!error && !isFocus) && <Text style={[styles.textError, hasPassword && { right: 40 }]}>{error}</Text>}
      {hasPassword && (
        <Pressable
          onPress={() => setShowPass(!showPass)}
          style={styles.btn_icon}
        >
          <Icon 
            name={showPass ? "eye-off" : "eye"}
            color={colors.grayPrimary}
            size={24}
          />
        </Pressable>
      )}
    </Pressable>
  );
};

export default React.memo(TextInput01);
