import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton } from "components/button";
import React from "react";
import useStyles from "./styles.css";

const ShopButton = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { hudHeight } = React.useContext(DimensionContext);

  return (
    <ImageButton
      source={{uri: images.btn_shop}}
      onPress={() => navigation.navigate("BuyShop")}
      style={[styles.btn_style, { width: hudHeight * 1.2, height: hudHeight * 1.2 }]}
    />
  );
};

export default ShopButton;
