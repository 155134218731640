import { Asset } from "expo-asset";
import { useCallback, useEffect, useState } from "react";

function useCacheImage(arrayAssets: any[]) {
  const [appIsReady, setAppIsReady] = useState(false);

  function cacheImages(assets: any[]) {
    return assets.map(image => {
      if (typeof image === "string") {
        return Asset.fromURI(image).downloadAsync();
      } else {
        return Asset.fromModule(image).downloadAsync();
      }
    });
  }

  const loadResourcesAndDataAsync = useCallback(async () => {
    try {
      const imageAssets = cacheImages(arrayAssets);
      await Promise.all(imageAssets);
    } catch (e) {
      console.warn(e);
    } finally {
      setAppIsReady(true);
    }
  },[arrayAssets]);
 
  useEffect(() => {
    loadResourcesAndDataAsync();
  }, []);

  return appIsReady;
}

export default useCacheImage;
