import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import { selectedAuthToken } from "../slices/auth.slice";
import { settingsActions } from "../slices/settings.slice";
import { selectedUserEmail } from "../slices/user.slice";

type ServiceOperators = {
  onChangeSettings: (params: boolean) => void;
  onChangeProfile: (params: boolean) => void;
  onDeactivatingRequest: () => void;
};

export const useSettingsService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();
  const email  = useAppSelector(selectedUserEmail);
  const token  = useAppSelector(selectedAuthToken);

  return {
    onChangeSettings: useCallback(
      (params: boolean) =>  dispatch(settingsActions.hasSettingsOpen(params)),
      [dispatch]
    ),
    onChangeProfile: useCallback(
      (params: boolean) =>  dispatch(settingsActions.hasProfileOpen(params)),
      [dispatch]
    ),
    onDeactivatingRequest: useCallback(
      () =>  dispatch(settingsActions.deactivateAccount({email, token})),
      [dispatch, email, token]
    ),
  };
};

export default useSettingsService;
