export default {
  colorPrimary: "#E05151",
  colorPrimaryDark: "#04104C",
  colorPrimaryTransparent: "rgba(255, 212, 43, 0.1)",
  navyblue: "#006DBC",
  transparent: "transparent",
  translucent: "rgba(0, 0, 0, 0.5)",
  translucent1: "rgba(0, 0, 0, 0.08)",
  translucent2: "rgba(0, 0, 0, 0.03)",
  translucent3: "rgba(0, 0, 0, 0.7)",
  white: "#FFFFFF",
  white1: "rgba(255, 255, 255, 0.2)",
  white2: "#D9D9D9",
  offwhite2: "rgba(255,255,255,0.5)",
  red: "red",
  red1: "#FE2424",
  red2: "#FF4F79",
  red3: "#A80909",
  red4: "#ED0808",
  red5: "#4E0408",
  red6: "#FF0000",
  red7: "#E01B1A",
  red8: "rgba(106, 1, 1, 0.7)",
  blue: "#00649C",
  blue1: "#2B70E5",
  blue2: "rgba(4, 18, 52, 0.77)",
  blue3: "#000058",
  blue4: "#0A618D",
  skyblue: "#03FFFF",
  skyblue1: "#9DE8FF",
  skyblue2: "#26F7FF",
  skyblue3: "#3E70F1",
  black: "#101010",
  black1: "#212221",
  black2: "#rgba(0,0,0,0.2)",
  black3: "#19083F",
  brown: "#663A28",
  green: "green",
  green1: "#3E9D1C",
  green2: "#05AD65",
  green3: "#29FF3F",
  green4: "#8EFF49",
  green5: "#2B6634",
  grayPrimary: "#A3A3A3",
  gray: "gray",
  gray1: "#4E8979",
  gray2: "#C9C9C9",
  gray3: "#6D6E71",
  orange: "#F26234",
  orange1: "#F3AE22",
  yellow: "#F4F6B5",
  yellow1: "#FFDF38",
  yellow2: "#F6E4B5",
  yellow3: "#FFDF38",
  yellow4: "#FFC149",
  yellow5: "#FFCA4C",
  yellow6: "#F3D527",
  yellow7: "#FFD15A",
  yellow8: "#FFE55A",
  yellow9: "#FFCB59",
  pink: "#ED1C24",
  pink2: "#F04A49",
  purple: "#F10074",
  violet: "rgb(176,0,180)",
  violet1: "#6C0B7B",
  violet2: "#980CC9",
  violet3: "#4282FF",
  violet4: "#71308F",
  progress: "#f0771f",
};
