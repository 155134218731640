import { colors } from "@src/theme";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.black1,
    },
    bodyStyle: {
      width: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "hidden",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute"
    },
    i_logo: {
      bottom: "18%",
      width: "30%",
      height: "55%",
      position: "absolute",
      alignSelf: "center",
    },
    i_elements: {
      bottom: "18%",
      width: "60%",
      height: "55%",
      position: "absolute",
      alignSelf: "center",
    },
    i_referee: {
      bottom: "1%",
      width: "16.3%",
      height: "73%",
      right: "4%",
      position: "absolute",
    },

    // PROGRESS
    progress_container: {
      height: "100%",
      borderRadius: 15,
      overflow: "hidden",
      backgroundColor: colors.progress,
    },
    t_loading: {
      fontSize: 16,
      bottom: "12%",
      fontWeight: "500",
      color: colors.white,
      alignSelf: "center",
      position: "absolute",
    },
    t_progress: {
      fontSize: 16,
      fontWeight: "500",
      color: colors.white,
      alignSelf: "center",
      position: "absolute",
    },
    v_progress: {
      height: "5%",
      width: "24%",
      bottom: "5%",
      position: "absolute",
      alignSelf: "center",
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "center",
    },
    i_gameworkz: {
      width: "20%",
      height: "11%",
      position: "absolute",
      left: "6%",
      bottom: "10%",
    },
    i_progress_bg: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      position: "absolute",
    },
    v_maskprogress: {
      width: "98%",
      height: "80%",
      borderRadius: 100,
      justifyContent: "center"
    },
    v_mask: {
      left: 0,
      width: "1%",
      height: "80%",
      position: "absolute",
      backgroundColor: colors.progress,
    },
    t_version: {
      bottom: "2%",
      fontSize: 10,
      color: "white",
      alignSelf: "center",
      position: "absolute",
    },
  },
);

export default useStyles;
