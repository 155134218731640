import React from "react";
import { AvatarProvider } from "./AvatarProvider";
import AvaterScreen from "./AvatarScreen";

const Avatar = () => {
  return (
    <AvatarProvider>
      <AvaterScreen />
    </AvatarProvider>
  );
};

export default React.memo(Avatar);
