import { CDN_URL } from "@env";
import { HotDealsButtonTypes } from "./image-types/hotdeals-button-types";

const HotDealsButtonAssets: HotDealsButtonTypes = {
  "hotdeals-button_img_0": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/img_0.png",
  "hotdeals-button_img_1": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/img_1.png",
  "hotdeals-button_seq_0_0": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_0.png",
  "hotdeals-button_seq_0_1": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_1.png",
  "hotdeals-button_seq_0_2": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_2.png",
  "hotdeals-button_seq_0_3": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_3.png",
  "hotdeals-button_seq_0_4": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_4.png",
  "hotdeals-button_seq_0_5": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_5.png",
  "hotdeals-button_seq_0_6": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_6.png",
  "hotdeals-button_seq_0_7": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_7.png",
  "hotdeals-button_seq_0_8": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_8.png",
  "hotdeals-button_seq_0_9": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_9.png",
  "hotdeals-button_seq_0_10": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_10.png",
  "hotdeals-button_seq_0_11": CDN_URL + "/image-assets/animation-assets/hot-deals-button/images/seq_0_11.png",
};

export default HotDealsButtonAssets;
