import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAvatarTier } from "@src/ducks/slices/lobby.slice";
import { ImageButton06 } from "components/button";
import SprinkerStar from "components/sprinkler-star";
import React, { useContext } from "react";
import { Image, View } from "react-native";
import { AvatarContext } from "../AvatarProvider";
import useStyles from "./styles.css";

const Tier = () => {
  const styles = useStyles();
  const tier = useAppSelector(selectedAvatarTier);
  const { setShowScreen } = useContext(AvatarContext);
  const { baseWidth, baseHeight } = React.useContext(DimensionContext);

  return (
    <View style={styles.v_container}>
      <ImageButton06
        style={[
          styles.i_yourtier,
          { width: baseWidth * 0.1841, height: baseHeight * 0.259},
        ]}
        source={images.your_tier}
        label="YOUR TIER"
        labelStyle={{fontSize: baseWidth * 0.023, marginTop: "10%"}}
      />
      <View style={styles.t_topdivider} />
      <View
        style={[
          styles.v_tiercenter,
          { width: baseWidth * 0.2774, height: baseWidth * 0.3056 },
        ]}
      >
        <Image
          style={styles.i_tierlevel}
          source={tier.tierAvatar}
          resizeMode="cover"
          resizeMethod="scale"
        />
        <SprinkerStar WIDTH={baseWidth * 0.2774} HEIGHT={baseWidth * 0.25} />
      </View>
      <ImageButton06
        onPress={() => setShowScreen("tier")}
        style={[
          styles.btn_tier,
          { height: baseWidth * 0.05, width: baseWidth * 0.15 },
        ]}
        label="TIER REWARDS"
        source={images["btn-big"]}
        labelStyle={{fontSize: baseWidth * 0.012}}
      />
    </View>
  );
};

export default Tier;
