import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    vw_topnav: {
      top: 0,
      zIndex: 10,
      width: "100%",
      height: "17.87%",
      position: "absolute",
    },
    v_topdivider: { width: 100, height: "2%" },
    style_navtop: {
      top: 0,
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    wrapcenter: {
      flex: 1,
      zIndex: 3,
      flexDirection: "row",
    },
    leftStyle: {
      flex: 1,
      alignItems: "flex-end",
    },
    leftStyleCenter: { 
      width: "90%", 
      height: "92%",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    width10: { width: 15 },
    width30: { width: 24 },
    middleStyle: {
      top: "20%",
      width: "16%",
      borderRadius: 100,
      alignItems: "center",
      justifyContent: "center",
    },
    rightStyle: {
      flex: 1,
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    width16: { width: 16 },
  },
);

export default useStyles;
