import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { authActions, selectedAuthSession } from "@src/ducks/slices/auth.slice";
import { selectedHourlyReward } from "@src/ducks/slices/lobby.slice";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, View } from "react-native";
import { check3hoursReward, collect3HoursRewards } from "utils/api";
import { CoinContext } from "../../main/CoinProvider";
import useStyles from "./styles.css";
import Timer from "./timer";

const Collect = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const session  = useAppSelector(selectedAuthSession);
  const checker = useAppSelector(selectedHourlyReward);
  const { on3HourTime, onFetchMissions } = useLobbyService();
  const { onAdditionalCurrency } = React.useContext(CoinContext);
  const { baseWidth, hudHeight } = React.useContext(DimensionContext);

  const onCollect = async () => {
    try{
      setLoading(true);
      await collect3HoursRewards(session, session.token);
      const hourlyReward = await check3hoursReward(session, session.token);
      on3HourTime(hourlyReward);
      setLoading(false);
      onAdditionalCurrency({silver: 50000, gwz: 0, bg: 0});
      onFetchMissions();
    }catch(e: any){
      console.log("eee", e);
      setLoading(false);

      if(e.status === 401 && e.error === "Unauthorized"){
        dispatch(authActions.logout());
        dispatch(authActions.setErrorMessage("Session Expired"));
      }
    }
  };

  return (
    <View style={[styles.container]}>
      {(checker.timer > 0 || checker.id === null) ? null : <ImageButton06
        isLoad={loading}
        onPress={onCollect}
        disabled={loading}
        style={[
          styles.btnCollect,
          { width: baseWidth * 0.0602, height: hudHeight * 0.4,  },
        ]}
        label="COLLECT"
        labelStyle={{fontSize: hudHeight * 0.1}}
        source={images["btn-green"]}
      />}
      <View style={[styles.v_timer, { width: baseWidth * 0.102, height: hudHeight * 0.3306, left: baseWidth * 0.024 }]}>
        <Image style={styles.i_timer_mask} source={{uri: images.hourglass_frame}} resizeMode="stretch" />
        <Timer baseHeight={hudHeight} />
      </View>
      <Image
        source={{uri: images.btn_hourglass}}
        style={[styles.imageCollect, 
          { 
            width: hudHeight * 0.71, 
            height: hudHeight * 0.8, 
          }]}
        resizeMode="stretch"
      />
    </View>
  );
};

export default React.memo(Collect);
