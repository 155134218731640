import SVGSettings from "@assets/svg/settings";
import { useNavigation } from "@react-navigation/native";
import { ImageButton05 } from "components/button";
import React from "react";
import { View } from "react-native";
import useStyles from "./styles.css";

const Policy = ({baseWidth, baseHeight}: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  // const onPolicy = () => Linking.openURL("https://fortune8.online/privacy-policy");

  return (
    <View style={styles.v_policy}>
      <ImageButton05 scaleSize={0.98} >
        <SVGSettings name="btn-privacy-policy" width={baseWidth * 0.2929} height={baseHeight * 0.083} />
      </ImageButton05>
      <ImageButton05 scaleSize={0.98} onPress={() => navigation.navigate("Disclaimer")} >
        <SVGSettings name="btn-disclaimer" width={baseWidth * 0.2304} height={baseHeight * 0.083} />
      </ImageButton05>
    </View>
  );
};

export default Policy;
