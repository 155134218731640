import images from "@assets/images";

const TierLevel = [
  {
    id: 1,
    gem: "Sapphire",
    tier_icon: images.Player_level_Cooper,
  },
  {
    id: 2,
    gem: "Emerald",
    tier_icon: images.Player_level_Bronze,
  },
  {
    id: 3,
    gem: "Ruby",
    tier_icon: images.Player_level_Silver,
  },
  {
    id: 4,
    gem: "Diamond",
    tier_icon: images.Player_level_Gold,
  },
];

export default TierLevel;
