import { SagaIterator } from "@redux-saga/core";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { claimTierUpgradeBonus } from "utils/api";
import { tierActions } from "../slices/tier.slice";

// Types
import { selectedAuthToken } from "../slices/auth.slice";
import * as Types from "../types";

function* handleClaimTierUpgradeBonusRequest(action: {
    type: typeof tierActions.claimTierUpgradeBonusRequest,
    payload: Types.Transaction;
  }): SagaIterator {
  try {
    // claim reward
    const accessToken = yield select(selectedAuthToken);
    const result = yield call(claimTierUpgradeBonus, action.payload, accessToken);
    yield put(tierActions.claimTierUpgradeBonusSuccess(result));
    
  } catch (error) {
    yield put(tierActions.claimTierUpgradeBonusFailed(error));
  }
}

function* tierWatcherSaga(): SagaIterator {
  yield takeEvery(tierActions.claimTierUpgradeBonusRequest.type, handleClaimTierUpgradeBonusRequest);
}

export default tierWatcherSaga;