import * as React from "react";
import Svg, { Defs, LinearGradient, Path, Stop, SvgProps } from "react-native-svg";
const SvgComponent = ({ width, height, fill, fill2 }: SvgProps & any) => (
  <Svg fill="none" width={width} height={height} viewBox="0 0 63 54">
    <Path
      fill={fill}
      d="M31.841 51.477c-2.544-2.281-5.118-4.497-7.588-6.821-4.778-4.496-9.582-8.97-14.217-13.61-2.79-2.792-5.1-5.993-6.678-9.677-3-7.004.766-15.718 8.143-18.76 8.096-3.338 16.909.502 20.034 8.693.08.208.172.408.29.687.473-1.003.853-1.956 1.35-2.84 3.18-5.688 9.47-8.63 15.953-7.493 6.094 1.069 11.006 6.043 12.102 12.246.723 4.1-.573 7.706-2.753 11.088-2.788 4.327-6.335 8.006-10.083 11.486-5.308 4.927-10.703 9.759-16.064 14.63-.14.127-.304.23-.491.371z"
    />
    <Path
      fill={fill2}
      d="M31.842 51.478c-2.545-2.282-5.118-4.498-7.589-6.822-4.777-4.495-9.581-8.97-14.216-13.61-2.79-2.792-5.1-5.992-6.678-9.677-3.001-7.003.765-15.717 8.143-18.76 8.095-3.337 16.908.503 20.034 8.693.079.208.171.408.29.687.472-1.003.852-1.955 1.349-2.84 3.18-5.687 9.47-8.63 15.953-7.493C55.223 2.725 60.135 7.7 61.23 13.903c.723 4.1-.573 7.706-2.753 11.088-2.787 4.326-6.335 8.006-10.083 11.485-5.308 4.927-10.703 9.76-16.064 14.63-.14.127-.303.23-.49.372z"
      opacity={0.75}
      style={{ mixBlendMode: "screen" }}
    />
    <Path
      stroke="url(#a)"
      d="m31.767 53.18-.808-.723q-1.188-1.063-2.38-2.116c-1.73-1.532-3.516-3.114-5.232-4.727l-1.613-1.516c-4.18-3.93-8.502-7.99-12.63-12.122-3.06-3.064-5.4-6.46-6.955-10.088-1.55-3.614-1.53-7.73.05-11.591 1.65-4.03 4.857-7.275 8.8-8.901 4.229-1.743 8.798-1.74 12.865.013 3.434 1.479 6.214 4.071 7.993 7.414q.081-.156.169-.31C35.489 2.322 42.29-.877 49.359.366 56.04 1.54 61.335 6.89 62.533 13.68c.705 3.998-.23 7.82-2.94 12.028-3.099 4.808-7.13 8.798-10.294 11.736-3.783 3.513-7.673 7.032-11.435 10.435q-2.325 2.098-4.64 4.206c-.15.137-.3.245-.433.337q-.072.05-.153.11l-.868.65zM17.474 2.723c-1.832 0-3.685.369-5.47 1.103-3.296 1.36-5.98 4.08-7.366 7.461-1.312 3.206-1.336 6.604-.066 9.565 1.423 3.321 3.577 6.44 6.4 9.264 4.1 4.103 8.408 8.15 12.573 12.064l1.615 1.519c1.684 1.584 3.458 3.156 5.171 4.672q.773.682 1.544 1.368 2.103-1.91 4.213-3.816c3.756-3.398 7.639-6.911 11.408-10.412 3.051-2.834 6.931-6.672 9.872-11.233 2.33-3.619 3.146-6.843 2.563-10.148-.99-5.617-5.527-10.215-11.03-11.18-5.948-1.043-11.665 1.64-14.571 6.837-.309.55-.578 1.15-.86 1.785-.143.318-.288.642-.444.973l-1.254 2.666-1.264-2.963a11 11 0 0 1-.2-.482c-1.392-3.645-4.05-6.469-7.486-7.948a13.5 13.5 0 0 0-5.34-1.1z"
    />
    <Path
      fill="url(#b)"
      d="m31.767 53.18-.808-.723q-1.188-1.063-2.38-2.116c-1.73-1.532-3.516-3.114-5.232-4.727l-1.613-1.516c-4.18-3.93-8.502-7.99-12.63-12.122-3.06-3.064-5.4-6.46-6.955-10.088-1.55-3.614-1.53-7.73.05-11.591 1.65-4.03 4.857-7.275 8.8-8.901 4.229-1.743 8.798-1.74 12.865.013 3.434 1.479 6.214 4.071 7.993 7.414q.081-.156.169-.31C35.489 2.322 42.29-.877 49.359.366 56.04 1.54 61.335 6.89 62.533 13.68c.705 3.998-.23 7.82-2.94 12.028-3.099 4.808-7.13 8.798-10.294 11.736-3.783 3.513-7.673 7.032-11.435 10.435q-2.325 2.098-4.64 4.206c-.15.137-.3.245-.433.337q-.072.05-.153.11l-.868.65zM17.474 2.723c-1.832 0-3.685.369-5.47 1.103-3.296 1.36-5.98 4.08-7.366 7.461-1.312 3.206-1.336 6.604-.066 9.565 1.423 3.321 3.577 6.44 6.4 9.264 4.1 4.103 8.408 8.15 12.573 12.064l1.615 1.519c1.684 1.584 3.458 3.156 5.171 4.672q.773.682 1.544 1.368 2.103-1.91 4.213-3.816c3.756-3.398 7.639-6.911 11.408-10.412 3.051-2.834 6.931-6.672 9.872-11.233 2.33-3.619 3.146-6.843 2.563-10.148-.99-5.617-5.527-10.215-11.03-11.18-5.948-1.043-11.665 1.64-14.571 6.837-.309.55-.578 1.15-.86 1.785-.143.318-.288.642-.444.973l-1.254 2.666-1.264-2.963a11 11 0 0 1-.2-.482c-1.392-3.645-4.05-6.469-7.486-7.948a13.5 13.5 0 0 0-5.34-1.1z"
    />
    <Defs>
      <LinearGradient
        id="b"
        x1={0.998}
        x2={62.755}
        y1={26.635}
        y2={26.635}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#3ADAFB" />
        <Stop offset={0.19} stopColor="#3ADAFB" />
        <Stop offset={0.56} stopColor="#3ADAFB"/>
        <Stop offset={0.79} stopColor="#3ADAFB"/>
        <Stop offset={1} stopColor="#3ADAFB"/>
      </LinearGradient>
      <LinearGradient
        id="a"
        x1={0.866}
        x2={62.887}
        y1={26.654}
        y2={26.654}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#3ADAFB" />
        <Stop offset={0.48} stopColor="#3ADAFB"/>
        <Stop offset={1} stopColor="#3ADAFB"/>
      </LinearGradient>
    </Defs>
  </Svg>
);
export default SvgComponent;
