import { SagaIterator } from "@redux-saga/core";
import { call, put, select, takeEvery } from "redux-saga/effects";

// Types
import * as Types from "../types";

// API
import {
  check3hoursReward,
  checkDailyReward,
  createMyFavoriteGames,
  deleteMyFavoriteGames,
  fetchAvatarTier,
  fetchGames,
  fetchMissions,
  fetchNotifications,
  fetchProducts,
  fetchRandomRewards,
  fetchTierUpgradeBonuses,
  fetchUserDetails,
  jadeEggAccumulation,
  purchaseItem,
  recordPurchase,
  recordPurchaseJade,
  recordPurchaseSubscription,
  walletBalance
} from "@src/utils/api";

// Slice
import { compileTransformation, featuresFilter, getGamesByCategoryId, switchSelectedFavoriteGames } from "utils/transform-helper";
import { authActions, selectedAuthToken } from "../slices/auth.slice";
import { lobbyActions, selectedCategoryId, selectedGamesOrigin } from "../slices/lobby.slice";
import { tierActions } from "../slices/tier.slice";
import { selectedUserUserID, userActions } from "../slices/user.slice";

function* handleLobbyRequest(): SagaIterator {
  try {
    // const token = yield call(checkingAuthentication, action.payload);
    const accessToken = yield select(selectedAuthToken);
    // if(action.payload.token !== token){
    //   yield put(authActions.refreshToken(token));
    // }

    const userDetails = yield call(fetchUserDetails, accessToken);
    yield put(userActions.fetchUserDetails(userDetails));
    const userId = userDetails.id;

    const jadeEgg = yield call(jadeEggAccumulation, {userId}, accessToken);
    yield put(lobbyActions.jadeAmount(jadeEgg.data.amount));

    const randomRewards = yield call(fetchRandomRewards, {userId}, accessToken);
    yield put(lobbyActions.randomRewards(randomRewards.data));

    const missions = yield call(fetchMissions, { userId }, accessToken);
    yield put(lobbyActions.missions(missions.data));

    const avatarTier = yield call(fetchAvatarTier, { userId }, accessToken);
    yield put(lobbyActions.avatarTier(avatarTier));

    const gamesOrigin = yield call(fetchGames, { userId }, accessToken);
    const gamesTransform = yield call(compileTransformation, gamesOrigin);
    const featuredList = yield call(featuresFilter, gamesOrigin);
    yield put(lobbyActions.games({gamesOrigin, transform: gamesTransform, featuredList}));

    const serverTime = yield call(checkDailyReward, { userId }, accessToken);
    yield put(lobbyActions.serverTime(serverTime));

    const hourlyReward = yield call(check3hoursReward, { userId }, accessToken);
    yield put(lobbyActions.hourlyReward(hourlyReward));

    const notifications = yield call(fetchNotifications, { userId }, accessToken);
    yield put(lobbyActions.notifications(notifications.data));

    const products = yield call(fetchProducts, accessToken);
    yield put(lobbyActions.products(products));

    const tierUpgradeBonuses = yield call(fetchTierUpgradeBonuses, { userId }, accessToken);

    yield put(tierActions.tierUpgradeBonuses(tierUpgradeBonuses.content));

    yield put(lobbyActions.lobbySuccess());
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
    yield put(lobbyActions.lobbySuccess());
    yield put(lobbyActions.lobbyFailure(error?.error ?? error));
  }
}

function* handlePurchaseRequest(action: {
  type: typeof lobbyActions.purchaseRequest;
  payload: Types.Transaction;
}): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);
    const result = yield call(purchaseItem, {...action.payload, userId}, accessToken);

    if (action.payload.isShop || action.payload.isJadeEgg) {
      const params = {
        token: accessToken,
        userId: userId,
        paymentRefNo: action.payload.transactionNo,
        purchaseItem: action.payload.purchaseItem,
      };
      yield call(recordPurchase, params, accessToken);
      yield put(lobbyActions.updateMissions("purchase 1x on shop"));
    }

    if (action.payload.isSubscription) {
      const params = {
        userId: userId,
        paymentRefNo: action.payload.transactionNo,
        subscriptionId: action.payload.purchaseItem,
      };
      yield call(recordPurchaseSubscription, params, accessToken);
      yield put(lobbyActions.updateMissions("purchase 1x on shop"));
    }

    yield put(lobbyActions.purchaseSuccess({ ...action.payload, ...result }));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
    yield put(lobbyActions.purchaseFailure());
  }
}

function* handlePurchaseSuccess(action: {
  type: typeof lobbyActions.purchaseSuccess;
  payload: Types.Transaction;
}): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthToken);
    if(action.payload.isJadeEgg){
      yield call(recordPurchaseJade, action.payload, accessToken);
      const jadeEgg = yield call(jadeEggAccumulation, action.payload, accessToken);
      yield put(lobbyActions.jadeAmount(jadeEgg.data.amount));
    }

    const tierUpgradeBonuses = yield call(fetchTierUpgradeBonuses, {...action.payload}, accessToken);
    yield put(tierActions.tierUpgradeBonuses(tierUpgradeBonuses.content));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
  }
}

function* handleBalanceRequest(): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);
    const balance = yield call(walletBalance, { userId }, accessToken);
    yield put(userActions.updateWallet(balance.data));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
  }
}

function* handleSwitchCategory(action: {
  type: typeof lobbyActions.changeCategory;
  payload: number;
}): SagaIterator {
  try {
    const gamesOriginal = yield select(selectedGamesOrigin);
    const filterGamesByCategoryId = yield call(getGamesByCategoryId, gamesOriginal, action.payload);
    const gamesTransform = yield call(compileTransformation, filterGamesByCategoryId);
    yield put(lobbyActions.games({ transform: gamesTransform}));
  } catch (error: any) {
    // empty
  }
}

function* handleChangeFavoriteGames(action: {
  type: typeof lobbyActions.changeFavoriteGame;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const userId = yield select(selectedUserUserID);
    const categoryId = yield select(selectedCategoryId);
    let gamesOrigin = yield select(selectedGamesOrigin);
    gamesOrigin = yield call(switchSelectedFavoriteGames, gamesOrigin, action.payload.id);
    yield put(lobbyActions.games({ gamesOrigin }));
    gamesOrigin = yield call(getGamesByCategoryId, gamesOrigin, categoryId);
    const gamesTransform = yield call(compileTransformation, gamesOrigin);
    yield put(lobbyActions.games({ transform: gamesTransform }));

    if(action.payload.isFavourite === 1){
      yield call(deleteMyFavoriteGames, {
        "gameId": action.payload.id,
        "userId": userId
      },token);
    }else {
      yield call(createMyFavoriteGames, {
        "gameId": action.payload.id,
        "userId": userId
      }, token);
    }
  } catch (error: any) {
    // empty
  }
}

// Watcher Saga
function* lobbyWatcherSaga(): SagaIterator {
  yield takeEvery(lobbyActions.lobbyRequest.type, handleLobbyRequest);
  yield takeEvery(lobbyActions.purchaseRequest.type, handlePurchaseRequest);
  yield takeEvery(lobbyActions.purchaseSuccess.type, handlePurchaseSuccess);
  yield takeEvery(lobbyActions.balanceRequest.type, handleBalanceRequest);
  yield takeEvery(lobbyActions.changeCategory.type, handleSwitchCategory);
  yield takeEvery(lobbyActions.changeFavoriteGame.type, handleChangeFavoriteGames);
}

export default lobbyWatcherSaga;
