const Countries = [
  {
    "id": 2,
    "code": "ALB",
    "name": "Albania"
  },
  {
    "id": 4,
    "code": "AND",
    "name": "Andorra"
  },
  {
    "id": 8,
    "code": "ARM",
    "name": "Armenia"
  },
  {
    "id": 10,
    "code": "AUT",
    "name": "Austria"
  },
  {
    "id": 11,
    "code": "AZE",
    "name": "Azerbaijan"
  },
  {
    "id": 16,
    "code": "BLR",
    "name": "Belarus"
  },
  {
    "id": 17,
    "code": "BEL",
    "name": "Belgium"
  },
  {
    "id": 22,
    "code": "BIH",
    "name": "Bosnia and Herzegovina"
  },
  {
    "id": 26,
    "code": "BGR",
    "name": "Bulgaria"
  },
  {
    "id": 42,
    "code": "HRV",
    "name": "Croatia"
  },
  {
    "id": 44,
    "code": "CYP",
    "name": "Cyprus"
  },
  {
    "id": 45,
    "code": "CZE",
    "name": "Czechia"
  },
  {
    "id": 49,
    "code": "DNK",
    "name": "Denmark"
  },
  {
    "id": 58,
    "code": "EST",
    "name": "Estonia"
  },
  {
    "id": 63,
    "code": "FIN",
    "name": "Finland"
  },
  {
    "id": 64,
    "code": "FRA",
    "name": "France"
  },
  {
    "id": 67,
    "code": "GEO",
    "name": "Georgia"
  },
  {
    "id": 68,
    "code": "DEU",
    "name": "Germany"
  },
  {
    "id": 70,
    "code": "GRC",
    "name": "Greece"
  },
  {
    "id": 78,
    "code": "HUN",
    "name": "Hungary"
  },
  {
    "id": 79,
    "code": "ISL",
    "name": "Iceland"
  },
  {
    "id": 80,
    "code": "IND",
    "name": "India"
  },
  {
    "id": 84,
    "code": "IRL",
    "name": "Ireland"
  },
  {
    "id": 86,
    "code": "ITA",
    "name": "Italy"
  },
  {
    "id": 197,
    "code": "XXK",
    "name": "Kosovo"
  },
  {
    "id": 96,
    "code": "LVA",
    "name": "Latvia"
  },
  {
    "id": 198,
    "code": "LIE",
    "name": "Liechtenstein"
  },
  {
    "id": 101,
    "code": "LTU",
    "name": "Lithuania"
  },
  {
    "id": 102,
    "code": "LUX",
    "name": "Luxembourg"
  },
  {
    "id": 108,
    "code": "MLT",
    "name": "Malta"
  },
  {
    "id": 114,
    "code": "MCO",
    "name": "Monaco"
  },
  {
    "id": 116,
    "code": "MNE",
    "name": "Montenegro"
  },
  {
    "id": 123,
    "code": "NLD",
    "name": "Netherlands"
  },
  {
    "id": 129,
    "code": "MKD",
    "name": "North Macedonia"
  },
  {
    "id": 130,
    "code": "NOR",
    "name": "Norway"
  },
  {
    "id": 139,
    "code": "POL",
    "name": "Poland"
  },
  {
    "id": 140,
    "code": "PRT",
    "name": "Portugal"
  },
  {
    "id": 143,
    "code": "MDA",
    "name": "Republic of Moldova"
  },
  {
    "id": 144,
    "code": "ROU",
    "name": "Romania"
  },
  {
    "id": 151,
    "code": "SMR",
    "name": "San Marino"
  },
  {
    "id": 155,
    "code": "SRB",
    "name": "Serbia"
  },
  {
    "id": 159,
    "code": "SVK",
    "name": "Slovakia"
  },
  {
    "id": 160,
    "code": "SVN",
    "name": "Slovenia"
  },
  {
    "id": 165,
    "code": "ESP",
    "name": "Spain"
  },
  {
    "id": 169,
    "code": "SWE",
    "name": "Sweden"
  },
  {
    "id": 170,
    "code": "CHE",
    "name": "Switzerland"
  },
  {
    "id": 180,
    "code": "TUR",
    "name": "Turkey"
  },
  {
    "id": 184,
    "code": "UKR",
    "name": "Ukraine"
  },
  {
    "id": 186,
    "code": "GBR",
    "name": "United Kingdom of Great Britain and Northern Ireland"
  }
];

export default Countries;
