import { CreateResponsiveStyle } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    v_fullscreen: {
      zIndex: 5,
      width: "100%",
      height: "100%",
      alignItems: "center",
      backgroundColor: colors.translucent3,
    },
    i_bg: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    container: {
      // top: "15%",
      width: "94%",
      height: "85%",
      position: "absolute",
    },
    v_top_divider: { height: "15%" },
    v_bottom: {
      bottom: "3%",
      zIndex: 10,
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
    },
    btn_back: {
      width: "10%",
      height: "45%",
      left: "3%",
      position: "absolute",
    },
    btn_change: { width: "20%", height: "45%" },

    // ITEM LIST STYLES
    bodyStyle: {
      flex: 1,
      zIndex: 3,
      marginHorizontal: "1%",
    },
    contentContainerStyle: {
      paddingLeft: "10%",
      // paddingRight: "10%",
    },

    // ITEM SLOT STYLES
    slotContainerStyle: {
      heigth: "58%",
      alignItems: "center",
      justifyContent: "center",
    },
    slotImageStyle: {
      width: "100%",
      height: "80%",
    },
    i_active_shine: {
      width: "100%",
      height: "60%",
      bottom: "8%",
      position: "absolute",
      // backgroundColor: 'green'
    },
    slotImageStyle_active: {
      borderWidth: 5,
      borderColor: colors.orange,
    },

    i_prev: {
      zIndex: 6,
      width: "5%",
      height: "15%",
      left: "3%",
      bottom: "40%",
      alignSelf: "center",
      position: "absolute",
    },
    i_next: {
      zIndex: 6,
      width: "5%",
      height: "15%",
      right: "3%",
      bottom: "40%",
      alignSelf: "center",
      position: "absolute",
    },
  },
);

export default useStyles;
