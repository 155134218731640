import React, { useRef, useEffect } from 'react';
import { Animated } from 'react-native';

const TranslateYAnimated = ({
  children,
  style,
  duration,
  outputRange,
}: any) => {
  const animated = useRef(new Animated.Value(0)).current;

  const translateY = animated.interpolate({
    inputRange: [0, 1],
    outputRange: outputRange || [400, 0],
    extrapolate: 'clamp',
  });

  useEffect(() => {
    Animated.timing(animated, {
      toValue: 1,
      duration: duration || 300,
      useNativeDriver: false,
    }).start();
  }, []);

  return (
    <Animated.View
      style={[
        style,
        {
          transform: [{ translateY }],
        },
      ]}
    >
      {children}
    </Animated.View>
  );
};

export default TranslateYAnimated;
