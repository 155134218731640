import numeral from "numeral";
import React from "react";
import { Text, View } from "react-native";
import useStyles from "./styles.css";

const CountDown = ({ dailyTimer, baseHeight, onDone }: any) => {
  const styles = useStyles();
  const timerId = React.useRef<any>(null);
  const [timer, setTimer] = React.useState(100);

  React.useEffect(() => {
    setTimer(dailyTimer);
  }, [dailyTimer]);

  React.useEffect(() => {
    if (!timer) {
      timerId.current && clearInterval(timerId.current);
      onDone();
      return;
    }

    timerId.current = setInterval(() => {
      setTimer((lastTimerCount) => lastTimerCount - 1);
    }, 1000);

    return () => clearInterval(timerId.current);
  }, [timer]);

  return (
    <View style={[styles.v_timer, {
      width: baseHeight * 0.8,
      height: baseHeight * 0.27,
      borderRadius: baseHeight * 0.05
    }]}>
      <Text style={[styles.t_timer, {fontSize: baseHeight * 0.1}]}>
        {numeral(timer).format("00:00:00")}
      </Text>
    </View>
  );
};

export default CountDown;
