import React, { useState, useImperativeHandle } from "react";
import { isMobile } from "react-device-detect";
import { View, FlatList } from "react-native";
import { MAavatars } from "utils/mockdata";
import AvatarItem from "./AvatarItem";
import useStyles from "./styles.css";
import ActionButton from "./action-button";

const AvatarList = React.forwardRef(({ baseWidth }: any, ref) => {
  const styles = useStyles();
  const flatlist = React.useRef<any>(null);
  const contentOffset = React.useRef<any>(0);
  const [active, setActive] = useState<any>({});
  const onItem = (item: any) => setActive(item);
  const [contentWidth, setContentWidth] = React.useState(0);

  console.log("contentOffset.current", contentOffset.current, contentWidth);

  const onSetContent = () => {

    setContentWidth((baseWidth * 0.18) * MAavatars.length);
  };

  useImperativeHandle(ref, () => ({
    getActive: () => active,
  }));

  const onScroll = React.useCallback(
    (event: any) => {
      const contentOffsetX = event.nativeEvent.contentOffset.x;
      contentOffset.current = contentOffsetX;
    },
    [contentOffset.current]
  );

  return (
    <View style={[styles.bodyStyle, { top: baseWidth * 0.07}]}>
      <FlatList
        horizontal
        scrollEnabled
        ref={flatlist}
        data={MAavatars}
        onScroll={onScroll}
        onContentSizeChange={onSetContent}
        renderItem={({ item, index }) => {
          const isActive = active.id === item.id;

          return (
            <AvatarItem
              key={index.toString()}
              {...{ item, isActive, onPress: () => onItem(item), baseWidth }}
            />
          );
        }}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => index.toString()}
        contentContainerStyle={styles.contentContainerStyle}
      />
      {!isMobile && <ActionButton {...{ flatlist, contentOffset, contentWidth}}/>}
    </View>
  );
});

AvatarList.displayName = "AvatarList";

export default AvatarList;
