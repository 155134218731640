import images from "@assets/images";
import LeftButton from "@src/screens/home/lobby/LeftButton";
import RightButton from "@src/screens/home/lobby/RightButton";
import React from "react";
import { useWindowDimensions } from "react-native";
import useStyles from "./styles.css";

const ActionButton = ({ flatlist, contentOffset, contentWidth }: any) => {
  const styles = useStyles();
  const { width } = useWindowDimensions();
  const [isEndReached, setIsEndReached] = React.useState(false);
  const [isStartReached, setIsStartReached] = React.useState(true);
  const VWIDTH = width * 0.6;

  const toPage = React.useCallback(
    (value: number) => {
      if (value === 1) {
        contentOffset.current += VWIDTH;
        setIsStartReached(false);
      } else if (value === -1) {
        contentOffset.current -= VWIDTH;
        setIsEndReached(false);
      }

      if (contentOffset.current >= contentWidth - VWIDTH) {
        contentOffset.current = contentWidth - VWIDTH;
        setIsEndReached(true);
      }
      if (contentOffset.current < 0) {
        contentOffset.current = 0;
        setIsStartReached(true);
      }

      console.log("PRESSS", contentOffset.current, contentWidth - VWIDTH);

      flatlist.current?.scrollToOffset({
        offset: contentOffset.current,
        animated: true,
      });
    },
    [flatlist, contentWidth, contentOffset, VWIDTH]
  );

  const onLongPress = (count: number) => () => {
    if (count === 1) {
      contentOffset.current += VWIDTH;
      setIsStartReached(false);
    } else if (count === 0) {
      contentOffset.current -= VWIDTH;
      setIsEndReached(false);
    }

    if (contentOffset.current >= contentWidth - 150) {
      contentOffset.current = contentWidth - 150;
      setIsEndReached(true);
    }

    if (contentOffset.current < 0) {
      contentOffset.current = 0;
      setIsStartReached(true);
    }

    flatlist.current?.scrollToOffset({
      offset: contentOffset.current,
      animated: true,
    });
  };

  return (
    <>
      <LeftButton
        onPress={() => toPage(-1)}
        disabled={isStartReached}
        style={styles.i_prev}
        onLongPress={onLongPress(0)}
        source={{uri: images.arrow_right}}
      />
      <RightButton
        disabled={isEndReached}
        style={styles.i_next}
        onPress={() => toPage(1)}
        source={{uri: images.arrow_right}}
        onLongPress={onLongPress(1)}
      />
    </>
  );
};

export default React.memo(ActionButton);
