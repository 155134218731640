import { CreateResponsiveStyle } from "rn-responsive-styles";
import colors from "theme/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: { flexDirection: "row", alignItems: "center" },
    inputSx: {
      fontSize: 12,
      color: colors.black,
      minHeight: "100%",
      borderRadius: 1,
      height: "100%",
      maxHeight: "100%",
      textAlign: "center",
      backgroundColor: "white",
    },
  },
);

export default useStyles;
