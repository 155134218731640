import * as Font from "expo-font";

// fonts preloading
export const fontAssets = [
  {
    "OpenSans-Bold": require("./OpenSans-Bold.ttf"),
  },{
    "Roboto": require("./Roboto-Regular.ttf"),
  },{
    "Roboto-Light": require("./Roboto-Light.ttf"),
  },{
    "Roboto-Medium": require("./Roboto-Medium.ttf"),
  },{
    "Roboto-Bold": require("./Roboto-Bold.ttf"),
  },{
    "Inter-Bold": require("./Inter-Bold.ttf"),
  },
].map((x: any) => Font.loadAsync(x));
