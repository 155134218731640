import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      ...StyleSheet.absoluteFillObject,
      backgroundColor: colors.translucent,
    },
    bodyStyle: {
      width: "63%",
      height: "80%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    bottomHeight: {
      width: 100,
      height: "9.2%",
    },
    header: {
      zIndex: 1,
      fontSize: 30,
      paddingTop: 48,
      color: "#6C0B7B",
      marginBottom: 40,
      fontFamily: "Roboto-Medium",
    },

    // ENTER FIELDS COMPONENT STYLE
    v_profile_header: {
      width: "50%",
      marginTop: "2%",
      height: "18%",
      alignItems: "center",
      justifyContent: "center",
    },
    forgotTextStyle: { width: "100%", height: 60 },
    v_fields: {
      flex: 1,
      width: "100%",
      alignItems: "center",
    },
    v_name: {
      width: "70%",
      height: "10%",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "1.2%",
      justifyContent: "space-between",
    },
    inputEmailStyle: {
      width: "70%",
      height: "10%",
      borderRadius: 2,
      marginBottom: "1.2%",
    },
    fn_style: {
      marginBottom: 0,
      borderRadius: 2,
      width: "48%",
      height: "100%",
    },
    dd_field: {
      marginBottom: 0,
      borderRadius: 2,
      width: "23%",
      height: "100%",
    },
    lbl_bday: {
      fontFamily: "Roboto",
      fontSize: 13,
      color: colors.white,
    },
    textAlign: { textAlign: "center" },
    height20: { height: 6 },
    v_required: {
      width: "18%",
      height: "9%",
      marginTop: "1%",
      borderRadius: 6,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.skyblue,
    },
    t_required: {
      fontFamily: "Roboto",
      fontSize: 12,
      color: colors.black,
    },
    btnSubmit: { height: "14%", width: "20%", marginLeft: 10, marginTop: "2%" },
    btnClose: {
      zIndex: 4,
      top: "6%",
      right: "6%",
      height: 40,
      width: 40,
      position: "absolute",
    },
    imageCloseStyle: { height: "100%", width: "100%" },
  },
);

export default useStyles;
