import React, {
  createContext,
  useCallback,
  useState
} from "react";
import CoinsView from "./CoinsView";

export type Currency = {
  silver: number;
  bg: number;
  gwz: number;
}

interface CoinContextValue {
  currency: Currency;
  onAdditionalCurrency: (value: Currency) => void;
}

export const CoinContext = createContext<CoinContextValue>({
  currency: {silver: 0, bg: 0, gwz: 0} as Currency,
  onAdditionalCurrency: () => {},
});

interface CoinProviderProps {
  children: React.ReactElement;
}

const CoinProvider = ({ children }: CoinProviderProps) => {
  const [currency, setAdditionalCurrency] = useState<Currency>({} as Currency);

  const onAdditionalCurrency = useCallback((value: Currency) => {
    setAdditionalCurrency(value);
  },[currency]);

  return (
    <CoinContext.Provider value={{ currency, onAdditionalCurrency }}>
      {children}
      <CoinsView {...{ silver: currency.silver }} />
    </CoinContext.Provider>
  );
};

export default CoinProvider;
