import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyStyle: {
      width: "58%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    smallLogoContainer: {
      height: "48%",
      width: "80%",
      top: "-25%",
      position: "absolute",
    },
    bottomHeight: {
      width: 100,
      height: "18%",
    },

    // ENTER EMAIL COMPONENT STYLE
    viewForgotStyle: {
      width: "60%",
      height: "20%",
      alignItems: "center",
      justifyContent: "center",
    },
    forgotTextStyle: { width: "100%", height: "100%" },
    viewEmail: {
      width: "100%",
      height: "80%",
      alignItems: "center",
      justifyContent: "center",
    },
    txtEmailNote: {
      fontSize: 16,
      textAlign: "center",
      color: colors.white,
      fontFamily: "Roboto",
    },
    inputEmailStyle: { marginTop: "1%", height: "15%" },
    btnSubmit: { height: "17%", width: "20%", marginTop: "2%" },
    textAlign: { textAlign: "center", paddingLeft: 0, fontSize: 16 },

    // CHECK EMAIL
    v_check_email: {
      width: "55%",
      height: "55%",
      alignItems: "center",
      justifyContent: "center",
    },

    btnClose: {
      width: "6%",
      height: "12%",
      zIndex: 1,
      top: "5%",
      right: "4%",
      position: "absolute",
    },
  },
);

export default useStyles;
