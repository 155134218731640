import { DimensionContext } from "@src/DimensionProvider";
import React from "react";
import Lottie from "react-lottie-player";
import { StyleSheet, View } from "react-native";

const CoinsView = ({ silver }: any) => {
  const [visible, setVisible] = React.useState(false);
  const [animationData, setAnimationData] = React.useState();
  const { baseWidth, maxHeight } = React.useContext(DimensionContext);

  const onReset = () => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      setVisible(false);
    }, 2000);
  };

  React.useEffect(() => {
    if (silver > 0) {
      setVisible(true);
      onReset();
    }
  }, [silver]);

  React.useEffect(() => {
    import("@assets/json/coins-increment").then(async(res: any) => {
      setAnimationData(res.default);
    });
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <View style={[styles.container]}>
      <Lottie
        play
        loop={0}
        segments={[50, 100]}
        animationData={animationData}
        style={{ position: "absolute", width: baseWidth, height: "100%", maxHeight: maxHeight }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "transparent",
    zIndex: 200,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default CoinsView;
