import React, { createContext } from "react";

interface ContextValue {
  flatlist: any;
  contentOffset: any;
  contentWidth: number;
  isStartReached: boolean;
  isEndReached: boolean;
  onStartReached: (value: boolean) => void;
  onEndReached: (value: boolean) => void;
  onSetContentWidth: (value: number) => void;
}

export const LobbyContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const LobbyProvider = ({ children }: ProviderProps) => {
  const flatlist = React.useRef<any>(null);
  const contentOffset = React.useRef<any>(0);
  const [contentWidth, setContentWidth] = React.useState(0);
  const [isStartReached, setIsStartReached] = React.useState(true);
  const [isEndReached, setIsEndReached] = React.useState(false);

  const onSetContentWidth = React.useCallback(
    (value: number) => {
      setContentWidth(value);
    },
    [contentWidth]
  );

  const onStartReached = React.useCallback(
    (value: boolean) => {
      setIsStartReached(value);
    },
    [isStartReached]
  );

  const onEndReached = React.useCallback(
    (value: boolean) => {
      setIsEndReached(value);
    },
    [isEndReached]
  );

  const value = {
    flatlist,
    contentOffset,
    contentWidth,
    isStartReached,
    isEndReached,
    onStartReached,
    onEndReached,
    onSetContentWidth,
  };

  return (
    <LobbyContext.Provider value={value}>{children}</LobbyContext.Provider>
  );
};

export default LobbyProvider;
