import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useSignupService } from "@src/ducks/hooks";
import { ImageButton06 } from "components/button";
import DatePicker01 from "components/date-picker";
import { TextInput01, TextInput02 } from "components/textinput";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { Image, View } from "react-native";
import { useInputHelper } from "utils/inputs-helper";
import { Countries, MDates } from "utils/mockdata";
import validator from "validator";
import useStyles from "./styles.css";

const initialstates = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  password: "",
  cpassword: "",
  referral: "",
  changeOnNextLogon: 0,
  userTypeID: 1,
  partnerId: 2,
  gender: "",
  country: "",
  apiKey: "",
};

const EnterFields = () => {
  const styles = useStyles();
  const [error, setError] = useState<any>({});
  const { isLoading, onSignup }= useSignupService();
  const { baseWidth, maxHeight } = useContext(DimensionContext);
  const {state, onDispatch} = useInputHelper(initialstates);
  const onChangeText = (type: string) => (value: string) => {
    onDispatch(type)(value);
    setError({});
  };

  const onSubmit = () => {
    const newErr: any = {};

    if (!state.firstName) {
      newErr.firstName = "Required";
    }
    if (!state.lastName) {
      newErr.lastName = "Required";
    }
    if (!state.emailAddress) {
      newErr.emailAddress = "Required";
    } else if (!validator.isEmail(state.emailAddress.trim())) {
      newErr.emailAddress = "Invalid email format.";
    }
    if (!state.password) {
      newErr.password = "Required";
    }else if (state.password.length < 6) {
      newErr.password = "Password must be at least six characters.";
    }
    if (!state.cpassword) {
      newErr.cpassword = "Required";
    }else if (state.password !== state.cpassword) {
      newErr.cpassword = "Passwords do not match.";
    }
    if (!state.dob) {
      newErr.dob = "Required";
    }
    if (!state.country) {
      newErr.country = "Required";
    }
    if (!state.gender) {
      newErr.gender = "Required";
    }
    
    setError(newErr);

    if (_.isEmpty(newErr)) {
      const params = {...state};

      if (params.dob) {
        params.dateOfBirth = state.dob.format("DD-MM-YYYY");
      }
      
      if (params.country) {
        const country = _.find(Countries, function(o) { return o.name === params.country; });
        params.country = country?.id;
      }

      if (params.gender) {
        const index = _.findIndex(MDates.Gender, function(o) { return o === params.gender; });
        params.gender = index + 1;
      }

      console.log("params", params);

      onSignup(params);
    }
  };

  return (
    <View style={[styles.bodyStyle, { height: baseWidth * 0.3849}]}>
      <Image
        source={{uri: images.bg_transparent}}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <View style={styles.viewEmail}>
        <View style={[styles.viewForgotStyle]}>
          <Image
            resizeMode="contain"
            source={{uri: images["text-create-account"]}}
            style={styles.forgotTextStyle}
          />
        </View>
        <View style={styles.v_name}>
          <TextInput01
            value={state.firstName}
            placeholder="First Name *"
            error={error.firstName}
            containerStyle={styles.fn_style}
            inputStyle={{fontSize: baseWidth * 0.014}}
            onChangeText={onChangeText("firstName")}
          />
          <TextInput01
            value={state.lastName}
            placeholder="Last Name *"
            error={error.lastName}
            containerStyle={styles.fn_style}
            inputStyle={{fontSize: baseWidth * 0.014}}
            onChangeText={onChangeText("lastName")}
          />
          <TextInput01
            value={state.emailAddress}
            placeholder="Email *"
            error={error.emailAddress}
            containerStyle={styles.ti_email_style}
            inputStyle={{fontSize: baseWidth * 0.014}}
            onChangeText={onChangeText("emailAddress")}
          />
        </View>

        <View style={[styles.v_name, styles.v_password]}>
          <TextInput01
            hasPassword
            value={state.password}
            placeholder="Password *"
            error={error.password}
            containerStyle={styles.ti_password}
            inputStyle={{textAlign: "center", fontSize: baseWidth * 0.014}}
            onChangeText={onChangeText("password")}
          />
          <TextInput01
            hasPassword
            value={state.cpassword}
            placeholder="Confirm Password *"
            error={error.cpassword}
            containerStyle={styles.ti_password}
            inputStyle={{textAlign: "center", fontSize: baseWidth * 0.014}}
            onChangeText={onChangeText("cpassword")}
          />
          <TextInput01
            value={state.referral}
            error={error.referral}
            placeholder="Referral Code"
            containerStyle={styles.ti_referral}
            inputStyle={{fontSize: baseWidth * 0.014}}
            onChangeText={onChangeText("referral")}
          />
        </View>
        <View style={[styles.v_name, styles.v_password]}>
          
          <DatePicker01
            value={state.dob || ""}
            placeholder="DOB"
            error={error.dob}
            style={styles.ti_password}
            textField={{textAlign: "center", fontSize: baseWidth * 0.014}}
            onChange={onChangeText("dob")}
          />
          <TextInput02
            value={state.country}
            placeholder="Country"
            error={error.country}
            options={Countries}
            containerStyle={styles.ti_password}
            inputStyle={{textAlign: "center", fontSize: baseWidth * 0.014}}
            onChangeText={(e: any) => onDispatch("country")(e.name)}
          />
          <TextInput02
            value={state.gender}
            placeholder="Gender"
            error={error.gender}
            options={MDates.Gender}
            inputStyle={{textAlign: "center", fontSize: baseWidth * 0.014}}
            containerStyle={styles.ti_referral}
            onChangeText={onChangeText("gender")}
          />
        </View>
        <ImageButton06
          onPress={onSubmit}
          label="SIGN UP"
          isLoading={isLoading}
          source={images["btn-big"]}
          style={[styles.btnSubmit, { height: maxHeight * 0.1}]}
          labelStyle={{fontSize: baseWidth * 0.014}}
        />
      </View>
    </View>
  );
};

export default EnterFields;
