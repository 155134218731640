import React, { useRef, useEffect, useState, useCallback } from "react";
import {
  View,
  Text,
  Modal,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  useWindowDimensions,
  TouchableWithoutFeedback,
} from "react-native";

import { colors } from "@src/theme";

const TextDropDown = (props: any) => {
  const { visible, accessible, onClose, viewRef, value, onSelected, options } =
    props;
  const flatlist = useRef(null);
  const [measure, setMesasure] = useState<any>({});
  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;

  useEffect(() => {
    if (viewRef.current && viewRef.current.measure) {
      viewRef.current.measure(
        (fx: any, fy: any, width: any, height: any, px: any, py: any) => {
          setMesasure({ x: px, y: py, w: width, h: height });
        }
      );
    }
  }, [windowWidth, windowHeight]);

  const { w, x, y, h } = measure;

  const _calcPosition = () => {
    const dropdownHeight = y * 0.7;
    const bottomSpace = windowHeight - y - h;
    const showInBottom = bottomSpace >= y / 2;

    const positionStyle = {
      height: showInBottom ? bottomSpace * 0.8 : dropdownHeight,
      width: w,
      left: x,
      top: showInBottom ? y + h : y - dropdownHeight,
    };

    return positionStyle;
  };

  const RenderItem = useCallback(
    ({ item, index }: any) => {
      let newRow = item;

      if (item && item.name && item.name.common) {
        newRow = item.name.common;
      } else if (item && item.name) {
        newRow = item.name;
      } else {
        newRow = item;
      }

      return (
        <TouchableOpacity
          key={`${index}`}
          onPress={() => {
            onSelected(item);
            onClose();
          }}
          style={styles.v_item}
        >
          <Text style={styles.t_item}>{newRow}</Text>
        </TouchableOpacity>
      );
    },
    [value]
  );

  return (
    <Modal
      transparent
      visible={visible}
      animationType={"none"}
      onRequestClose={onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <TouchableWithoutFeedback accessible={accessible} onPress={onClose}>
        <View style={styles.modal}>
          <View style={[styles.dropdown, _calcPosition()]}>
            <FlatList
              ref={flatlist}
              data={options}
              getItemLayout={(item, index) => ({
                length: 50.7,
                offset: 50.7 * index,
                index,
              })}
              // eslint-disable-next-line react/no-unstable-nested-components
              ItemSeparatorComponent={() => (
                <View style={styles.itemSeparator} />
              )}
              keyExtractor={(_, index) => `${index}`}
              renderItem={RenderItem}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: { flexGrow: 1 },
  dropdown: {
    width: 400,
    position: "absolute",
    borderRadius: 4,
    backgroundColor: "white",
    justifyContent: "center",
    shadowColor: "gray",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.6,
    shadowRadius: 10,
  },
  itemSeparator: {
    height: 1,
    marginHorizontal: 10,
    backgroundColor: colors.translucent1,
  },
  v_item: {
    height: 40,
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 10,
  },
  t_item: {
    flex: 1,
    fontSize: 15,
    fontWeight: "500",
    color: colors.black,
    fontFamily: "Roboto",
  },
});

export default TextDropDown;
