import { CreateResponsiveStyle } from "rn-responsive-styles";
import { isTablet } from "utils/minheight";
import colors from "theme/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "9%",
      height: "30%",
      alignItems: "center",
      marginHorizontal: isTablet ? "1.5%" : "2%",
      justifyContent: "center",
    },
    size_egg: {
      width: "100%",
      height: "100%",
    },
    dotStyle: {
      top: -5,
      right: -5,
      zIndex: 3,
      width: 14,
      height: 14,
      borderRadius: 100,
      position: "absolute",
      backgroundColor: "red",
    },
    dotActiveStyle: {
      backgroundColor: colors.green3,
    },
  },
);

export default useStyles;
