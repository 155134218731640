import React, { useCallback, useState } from "react";
import Loading from "./Loading";

interface ProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  enableLoading: () => void;
  disableLoading: () => void;
}

export const LoadingContext = React.createContext<ContextValue>(
  {} as ContextValue
);

const LoadingProvider = ({ children }: ProviderProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const enableLoading = useCallback(() => {
    setLoading(true);
  }, [loading]);

  const disableLoading = useCallback(() => {
    setLoading(true);
  }, [loading]);

  return (
    <LoadingContext.Provider value={{ enableLoading, disableLoading }}>
      {children}
      <Loading isLoading={loading} />
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
