import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useLobbyService } from "@src/ducks/hooks";
import React from "react";
import { Image, Pressable, Text, View } from "react-native";
import useStyles from "./styles.css";

type Props = {
  onClose: () => void;
}

const ItemShop = ({onClose}: Props) => {
  const styles = useStyles();
  const { onChangeScreen } = useLobbyService();
  const { hudHeight } = React.useContext(DimensionContext);

  const onPress = () => {
    onChangeScreen({ screen: "gold-shop" });
    onClose();
  };

  return (
    <View style={styles.v_container_shop}>
      <Pressable onPress={onPress} style={styles.v_item_shop}>
        <Image
          resizeMode="stretch"
          source={{uri: images["gwz_shop"]}}
          style={styles.i_gwz_shop}
        />
        <Text style={[styles.t_gold_amount, { marginRight: 0, fontSize: hudHeight * 0.09}]}>Visit Gold Shop</Text>
      </Pressable>
    </View>
  );
};

export default ItemShop;
