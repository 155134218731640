import React from "react";
import { NavigationScreenProps } from "@src/navigators/NavigatorTypes";
import { selectAuthLoggingIn } from "@src/ducks/slices/auth.slice";
import { DimensionContext } from "@src/DimensionProvider";
import { useNavigation } from "@react-navigation/native";
import { ImageButton02 } from "@src/components/button";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Loading01 } from "@src/components/loading";
import { useAuthService } from "@src/ducks/hooks";
import { View, Text, Image, Linking } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import Facebook from "./Facebook";
import Google from "./Google";

const SignInOption = () => {
  const styles = useStyles();
  const { onResetLoading } = useAuthService();
  const { baseWidth } = React.useContext(DimensionContext);
  const isLoggingIn = useAppSelector(selectAuthLoggingIn);
  const navigation = useNavigation<NavigationScreenProps<"SignIn"| "SignUp">>();
  const onTerms = () =>
    Linking.openURL("https://fortune8.online/web-terms-%2B-conditions");

  React.useEffect(() => {
    onResetLoading();
  }, []);

  return (
    <View style={styles.container}>
      <Image
        source={{uri: images["bg-splash"]}}
        style={[styles.i_background]}
        resizeMode="stretch"
      />
      <View style={styles.container}>
        <View style={[styles.bodyStyle, { height: baseWidth * 0.24}]}>
          <Text style={[styles.t_signup_using, { fontSize: baseWidth * 0.024}]}> SIGN UP USING:</Text>
          <View style={styles.viewCenter}>
            <View style={[styles.v_social_login, { height: baseWidth * 0.052}]}>
              <Google />
              <Facebook />
            </View>
            <View style={[styles.separator, { height: baseWidth * 0.03}]}>
              <View style={styles.divider} />
              <Text style={[styles.txtOrstyle, { fontSize: baseWidth * 0.011}]}>Or</Text>
              <View style={styles.divider} />
            </View>
            <View style={[styles.v_social_login, { height: baseWidth * 0.052}]}>
              <ImageButton02
                style={styles.btn_reg_email}
                onPress={() => navigation.navigate("SignUp")} >
                <Image
                  style={styles.imageStyle}
                  source={{uri: images.mainRegisterEmailButton}}
                  resizeMode="stretch"
                />
              </ImageButton02>
              <ImageButton02
                style={styles.btn_reg_email}
                onPress={() => navigation.navigate("SignIn")} >
                <Image
                  style={styles.imageStyle}
                  source={{uri: images.main_login_button}}
                  resizeMode="stretch"
                />
              </ImageButton02>
            </View>
          </View>
          <Loading01 isLoading={isLoggingIn} />
        </View>
        <Image
          source={{uri: images["referee"]}}
          style={styles.i_referee}
          resizeMode="stretch"
        />
        <Text style={[styles.t_terms_condition, { fontSize: baseWidth * 0.012, lineHeight: baseWidth * 0.020}]}>By continuing to access the game, you agree that you’re 18+ years of age{"\n"}
          and you read our <Text style={styles.t_terms_condition_active} onPress={onTerms}>TERMS AND CONDITIONS</Text> and <Text style={styles.t_terms_condition_active}>PRIVACY POLICY.</Text></Text>
        <Text style={[styles.t_terms_condition, { fontSize: baseWidth * 0.012, lineHeight: baseWidth * 0.020}]}>Game Zone is Social Casino and in no way offer opportunity to win real money.</Text>
      </View>
    </View>
  );
};

export default SignInOption;
