import SVGDrawer from "@assets/svg/side-drawer";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton05 } from "components/button";
import React from "react";

type Props = {
  onPress: () => void;
  source: any;
}

function DrawerItem(props: Props) {
  const { baseWidth } = React.useContext(DimensionContext);

  return (
    <ImageButton05 onPress={props.onPress} >
      <SVGDrawer name={props.source} width={baseWidth * 0.05} height={baseWidth * 0.05}/>
    </ImageButton05>
  );
}

export default DrawerItem;