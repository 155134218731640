import { CDN_URL } from "@env";
import { GoldenBallTypes } from "./image-types/golden-ball-types";

const GoldenBallAssets: GoldenBallTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_7.png",
  "img_8": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_8.png",
  "img_9": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_9.png",
  "img_10": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_10.png",
  "img_11": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_11.png",
  "img_12": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_12.png",
  "img_13": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_13.jpg",
  "img_14": CDN_URL + "/image-assets/animation-assets/golden-ball/images/img_14.jpg",
  "seq_0_0": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_0.png",
  "seq_0_1": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_1.png",
  "seq_0_2": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_2.png",
  "seq_0_3": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_3.png",
  "seq_0_4": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_4.png",
  "seq_0_5": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_5.png",
  "seq_0_6": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_6.png",
  "seq_0_7": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_7.png",
  "seq_0_8": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_8.png",
  "seq_0_9": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_9.png",
  "seq_0_10": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_10.png",
  "seq_0_11": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_11.png",
  "seq_0_12": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_12.png",
  "seq_0_13": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_13.png",
  "seq_0_14": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_14.png",
  "seq_0_15": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_15.png",
  "seq_0_16": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_16.png",
  "seq_0_17": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_17.png",
  "seq_0_18": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_18.png",
  "seq_0_19": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_19.png",
  "seq_0_20": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_20.png",
  "seq_0_21": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_21.png",
  "seq_0_22": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_22.png",
  "seq_0_23": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_23.png",
  "seq_0_24": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_24.png",
  "seq_0_25": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_25.png",
  "seq_0_26": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_26.png",
  "seq_0_27": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_27.png",
  "seq_0_28": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_28.png",
  "seq_0_29": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_29.png",
  "seq_0_30": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_30.png",
  "seq_0_31": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_31.png",
  "seq_0_32": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_32.png",
  "seq_0_33": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_33.png",
  "seq_0_34": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_34.png",
  "seq_0_35": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_35.png",
  "seq_0_36": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_36.png",
  "seq_0_37": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_37.png",
  "seq_0_38": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_38.png",
  "seq_0_39": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_39.png",
  "seq_0_40": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_40.png",
  "seq_0_41": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_41.png",
  "seq_0_42": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_42.png",
  "seq_0_43": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_43.png",
  "seq_0_44": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_44.png",
  "seq_0_45": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_45.png",
  "seq_0_46": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_46.png",
  "seq_0_47": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_47.png",
  "seq_0_48": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_48.png",
  "seq_0_49": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_49.png",
  "seq_0_50": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_50.png",
  "seq_0_51": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_51.png",
  "seq_0_52": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_52.png",
  "seq_0_53": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_53.png",
  "seq_0_54": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_54.png",
  "seq_0_55": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_55.png",
  "seq_0_56": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_56.png",
  "seq_0_57": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_57.png",
  "seq_0_58": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_58.png",
  "seq_0_59": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_59.png",
  "seq_0_60": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_60.png",
  "seq_0_61": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_61.png",
  "seq_0_62": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_62.png",
  "seq_0_63": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_63.png",
  "seq_0_64": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_0_64.png",
  
  "seq_1_0": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_0.png",
  "seq_1_1": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_1.png",
  "seq_1_2": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_2.png",
  "seq_1_3": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_3.png",
  "seq_1_4": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_4.png",
  "seq_1_5": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_5.png",
  "seq_1_6": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_6.png",
  "seq_1_7": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_7.png",
  "seq_1_8": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_8.png",
  "seq_1_9": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_9.png",
  "seq_1_10": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_10.png",
  "seq_1_11": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_11.png",
  "seq_1_12": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_12.png",
  "seq_1_13": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_13.png",
  "seq_1_14": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_14.png",
  "seq_1_15": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_15.png",
  "seq_1_16": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_16.png",
  "seq_1_17": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_17.png",
  "seq_1_18": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_18.png",
  "seq_1_19": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_19.png",
  "seq_1_20": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_20.png",
  "seq_1_21": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_21.png",
  "seq_1_22": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_22.png",
  "seq_1_23": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_23.png",
  "seq_1_24": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_24.png",
  "seq_1_25": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_25.png",
  "seq_1_26": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_26.png",
  "seq_1_27": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_27.png",
  "seq_1_28": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_28.png",
  "seq_1_29": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_29.png",
  "seq_1_30": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_30.png",
  "seq_1_31": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_31.png",
  "seq_1_32": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_32.png",
  "seq_1_33": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_33.png",
  "seq_1_34": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_34.png",
  "seq_1_35": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_35.png",
  "seq_1_36": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_36.png",
  "seq_1_37": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_37.png",
  "seq_1_38": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_38.png",
  "seq_1_39": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_39.png",
  "seq_1_40": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_40.png",
  "seq_1_41": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_41.png",
  "seq_1_42": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_42.png",
  "seq_1_43": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_43.png",
  "seq_1_44": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_44.png",
  "seq_1_45": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_45.png",
  "seq_1_46": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_46.png",
  "seq_1_47": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_47.png",
  "seq_1_48": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_48.png",
  "seq_1_49": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_49.png",
  "seq_1_50": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_50.png",
  "seq_1_51": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_51.png",
  "seq_1_52": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_52.png",
  "seq_1_53": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_53.png",
  "seq_1_54": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_54.png",
  "seq_1_55": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_55.png",
  "seq_1_56": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_56.png",
  "seq_1_57": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_57.png",
  "seq_1_58": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_58.png",
  "seq_1_59": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_59.png",
  "seq_1_60": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_60.png",
  "seq_1_61": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_61.png",
  "seq_1_62": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_62.png",
  "seq_1_63": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_63.png",
  "seq_1_64": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_64.png",
  "seq_1_65": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_65.png",
  "seq_1_66": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_66.png",
  "seq_1_67": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_67.png",
  "seq_1_68": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_68.png",
  "seq_1_69": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_69.png",
  "seq_1_70": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_70.png",
  "seq_1_71": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_71.png",
  "seq_1_72": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_72.png",
  "seq_1_73": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_73.png",
  "seq_1_74": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_74.png",
  "seq_1_75": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_75.png",
  "seq_1_76": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_76.png",
  "seq_1_77": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_77.png",
  "seq_1_78": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_78.png",
  "seq_1_79": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_79.png",
  "seq_1_80": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_80.png",
  "seq_1_81": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_81.png",
  "seq_1_82": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_82.png",
  "seq_1_83": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_83.png",
  "seq_1_84": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_84.png",
  "seq_1_85": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_85.png",
  "seq_1_86": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_86.png",
  "seq_1_87": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_87.png",
  "seq_1_88": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_88.png",
  "seq_1_89": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_89.png",
  "seq_1_90": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_90.png",
  "seq_1_91": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_91.png",
  "seq_1_92": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_92.png",
  "seq_1_93": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_93.png",
  "seq_1_94": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_94.png",
  "seq_1_95": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_95.png",
  "seq_1_96": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_96.png",
  "seq_1_97": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_97.png",
  "seq_1_98": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_98.png",
  "seq_1_99": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_99.png",
  "seq_1_100": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_100.png",
  "seq_1_101": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_101.png",
  "seq_1_102": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_102.png",
  "seq_1_103": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_103.png",
  "seq_1_104": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_104.png",
  "seq_1_105": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_105.png",
  "seq_1_106": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_106.png",
  "seq_1_107": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_107.png",
  "seq_1_108": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_108.png",
  "seq_1_109": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_109.png",
  "seq_1_110": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_110.png",
  "seq_1_111": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_111.png",
  "seq_1_112": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_112.png",
  "seq_1_113": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_113.png",
  "seq_1_114": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_114.png",
  "seq_1_115": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_115.png",
  "seq_1_116": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_116.png",
  "seq_1_117": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_117.png",
  "seq_1_118": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_118.png",
  "seq_1_119": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_119.png",
  "seq_1_120": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_120.png",
  "seq_1_121": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_121.png",
  "seq_1_122": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_122.png",
  "seq_1_123": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_123.png",
  "seq_1_124": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_124.png",
  "seq_1_125": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_125.png",
  "seq_1_126": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_126.png",
  "seq_1_127": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_127.png",
  "seq_1_128": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_128.png",
  "seq_1_129": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_129.png",
  "seq_1_130": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_130.png",
  "seq_1_131": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_131.png",
  "seq_1_132": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_132.png",
  "seq_1_133": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_133.png",
  "seq_1_134": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_134.png",
  "seq_1_135": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_135.png",
  "seq_1_136": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_136.png",
  "seq_1_137": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_137.png",
  "seq_1_138": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_138.png",
  "seq_1_139": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_139.png",
  "seq_1_140": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_140.png",
  "seq_1_141": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_141.png",
  "seq_1_142": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_142.png",
  "seq_1_143": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_143.png",
  "seq_1_144": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_144.png",
  "seq_1_145": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_145.png",
  "seq_1_146": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_146.png",
  "seq_1_147": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_147.png",
  "seq_1_148": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_148.png",
  "seq_1_149": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_149.png",
  "seq_1_150": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_150.png",
  "seq_1_151": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_151.png",
  "seq_1_152": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_152.png",
  "seq_1_153": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_153.png",
  "seq_1_154": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_154.png",
  "seq_1_155": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_155.png",
  "seq_1_156": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_156.png",
  "seq_1_157": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_157.png",
  "seq_1_158": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_158.png",
  "seq_1_159": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_159.png",
  "seq_1_160": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_160.png",
  "seq_1_161": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_161.png",
  "seq_1_162": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_162.png",
  "seq_1_163": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_163.png",
  "seq_1_164": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_164.png",
  "seq_1_165": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_165.png",
  "seq_1_166": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_166.png",
  "seq_1_167": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_167.png",
  "seq_1_168": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_168.png",
  "seq_1_169": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_169.png",
  "seq_1_170": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_170.png",
  "seq_1_171": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_171.png",
  "seq_1_172": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_172.png",
  "seq_1_173": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_173.png",
  "seq_1_174": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_174.png",
  "seq_1_175": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_175.png",
  "seq_1_176": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_176.png",
  "seq_1_177": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_177.png",
  "seq_1_178": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_178.png",
  "seq_1_179": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_179.png",
  "seq_1_180": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_180.png",
  "seq_1_181": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_181.png",
  "seq_1_182": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_182.png",
  "seq_1_183": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_183.png",
  "seq_1_184": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_184.png",
  "seq_1_185": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_185.png",
  "seq_1_186": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_186.png",
  "seq_1_187": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_187.png",
  "seq_1_188": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_188.png",
  "seq_1_189": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_189.png",
  "seq_1_190": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_190.png",
  "seq_1_191": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_191.png",
  "seq_1_192": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_192.png",
  "seq_1_193": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_193.png",
  "seq_1_194": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_194.png",
  "seq_1_195": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_195.png",
  "seq_1_196": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_196.png",
  "seq_1_197": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_197.png",
  "seq_1_198": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_198.png",
  "seq_1_199": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_199.png",
  "seq_1_200": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_200.png",
  "seq_1_201": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_201.png",
  "seq_1_202": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_202.png",
  "seq_1_203": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_203.png",
  "seq_1_204": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_204.png",
  "seq_1_205": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_205.png",
  "seq_1_206": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_206.png",
  "seq_1_207": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_207.png",
  "seq_1_208": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_208.png",
  "seq_1_209": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_209.png",
  "seq_1_210": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_210.png",
  "seq_1_211": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_211.png",
  "seq_1_212": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_212.png",
  "seq_1_213": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_213.png",
  "seq_1_214": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_214.png",
  "seq_1_215": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_215.png",
  "seq_1_216": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_216.png",
  "seq_1_217": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_217.png",
  "seq_1_218": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_218.png",
  "seq_1_219": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_219.png",
  "seq_1_220": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_220.png",
  "seq_1_221": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_221.png",
  "seq_1_222": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_222.png",
  "seq_1_223": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_223.png",
  "seq_1_224": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_224.png",
  "seq_1_225": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_225.png",
  "seq_1_226": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_226.png",
  "seq_1_227": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_227.png",
  "seq_1_228": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_228.png",
  "seq_1_229": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_229.png",
  "seq_1_230": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_230.png",
  "seq_1_231": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_231.png",
  "seq_1_232": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_232.png",
  "seq_1_233": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_233.png",
  "seq_1_234": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_234.png",
  "seq_1_235": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_235.png",
  "seq_1_236": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_236.png",
  "seq_1_237": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_237.png",
  "seq_1_238": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_238.png",
  "seq_1_239": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_239.png",
  "seq_1_240": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_240.png",
  "seq_1_241": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_241.png",
  "seq_1_242": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_242.png",
  "seq_1_243": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_243.png",
  "seq_1_244": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_244.png",
  "seq_1_245": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_245.png",
  "seq_1_246": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_246.png",
  "seq_1_247": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_247.png",
  "seq_1_248": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_248.png",
  "seq_1_249": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_249.png",
  "seq_1_250": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_250.png",
  "seq_1_251": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_251.png",
  "seq_1_252": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_252.png",
  "seq_1_253": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_253.png",
  "seq_1_254": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_254.png",
  "seq_1_255": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_255.png",
  "seq_1_256": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_256.png",
  "seq_1_257": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_257.png",
  "seq_1_258": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_258.png",
  "seq_1_259": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_259.png",
  "seq_1_260": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_260.png",
  "seq_1_261": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_261.png",
  "seq_1_262": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_262.png",
  "seq_1_263": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_263.png",
  "seq_1_264": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_264.png",
  "seq_1_265": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_265.png",
  "seq_1_266": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_266.png",
  "seq_1_267": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_267.png",
  "seq_1_268": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_268.png",
  "seq_1_269": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_269.png",
  "seq_1_270": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_270.png",
  "seq_1_271": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_271.png",
  "seq_1_272": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_272.png",
  "seq_1_273": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_273.png",
  "seq_1_274": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_274.png",
  "seq_1_275": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_275.png",
  "seq_1_276": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_276.png",
  "seq_1_277": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_277.png",
  "seq_1_278": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_278.png",
  "seq_1_279": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_279.png",
  "seq_1_280": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_280.png",
  "seq_1_281": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_281.png",
  "seq_1_282": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_282.png",
  "seq_1_283": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_283.png",
  "seq_1_284": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_284.png",
  "seq_1_285": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_285.png",
  "seq_1_286": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_286.png",
  "seq_1_287": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_287.png",
  "seq_1_288": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_288.png",
  "seq_1_289": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_289.png",
  "seq_1_290": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_290.png",
  "seq_1_291": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_291.png",
  "seq_1_292": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_292.png",
  "seq_1_293": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_293.png",
  "seq_1_294": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_294.png",
  "seq_1_295": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_295.png",
  "seq_1_296": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_296.png",
  "seq_1_297": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_297.png",
  "seq_1_298": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_298.png",
  "seq_1_299": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_1_299.png",

  "seq_2_0": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_0.png",
  "seq_2_1": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_1.png",
  "seq_2_2": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_2.png",
  "seq_2_3": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_3.png",
  "seq_2_4": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_4.png",
  "seq_2_5": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_5.png",
  "seq_2_6": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_6.png",
  "seq_2_7": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_7.png",
  "seq_2_8": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_8.png",
  "seq_2_9": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_9.png",
  "seq_2_10": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_10.png",
  "seq_2_11": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_11.png",
  "seq_2_12": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_12.png",
  "seq_2_13": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_13.png",
  "seq_2_14": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_14.png",
  "seq_2_15": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_15.png",
  "seq_2_16": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_16.png",
  "seq_2_17": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_17.png",
  "seq_2_18": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_18.png",
  "seq_2_19": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_19.png",
  "seq_2_20": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_20.png",
  "seq_2_21": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_21.png",
  "seq_2_22": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_22.png",
  "seq_2_23": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_23.png",
  "seq_2_24": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_24.png",
  "seq_2_25": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_25.png",
  "seq_2_26": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_26.png",
  "seq_2_27": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_27.png",
  "seq_2_28": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_28.png",
  "seq_2_29": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_29.png",
  "seq_2_30": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_30.png",
  "seq_2_31": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_31.png",
  "seq_2_32": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_32.png",
  "seq_2_33": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_33.png",
  "seq_2_34": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_34.png",
  "seq_2_35": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_35.png",
  "seq_2_36": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_36.png",
  "seq_2_37": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_37.png",
  "seq_2_38": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_38.png",
  "seq_2_39": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_39.png",
  "seq_2_40": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_40.png",
  "seq_2_41": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_41.png",
  "seq_2_42": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_42.png",
  "seq_2_43": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_43.png",
  "seq_2_44": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_44.png",
  "seq_2_45": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_45.png",
  "seq_2_46": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_46.png",
  "seq_2_47": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_47.png",
  "seq_2_48": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_48.png",
  "seq_2_49": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_49.png",
  "seq_2_50": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_50.png",
  "seq_2_51": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_51.png",
  "seq_2_52": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_52.png",
  "seq_2_53": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_53.png",
  "seq_2_54": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_54.png",
  "seq_2_55": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_55.png",
  "seq_2_56": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_56.png",
  "seq_2_57": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_57.png",
  "seq_2_58": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_58.png",
  "seq_2_59": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_59.png",
  "seq_2_60": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_60.png",
  "seq_2_61": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_61.png",
  "seq_2_62": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_62.png",
  "seq_2_63": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_63.png",
  "seq_2_64": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_64.png",
  "seq_2_65": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_65.png",
  "seq_2_66": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_66.png",
  "seq_2_67": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_67.png",
  "seq_2_68": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_68.png",
  "seq_2_69": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_69.png",
  "seq_2_70": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_70.png",
  "seq_2_71": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_71.png",
  "seq_2_72": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_72.png",
  "seq_2_73": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_73.png",
  "seq_2_74": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_74.png",
  "seq_2_75": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_75.png",
  "seq_2_76": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_76.png",
  "seq_2_77": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_77.png",
  "seq_2_78": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_78.png",
  "seq_2_79": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_79.png",
  "seq_2_80": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_80.png",
  "seq_2_81": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_81.png",
  "seq_2_82": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_82.png",
  "seq_2_83": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_83.png",
  "seq_2_84": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_84.png",
  "seq_2_85": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_85.png",
  "seq_2_86": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_86.png",
  "seq_2_87": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_87.png",
  "seq_2_88": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_88.png",
  "seq_2_89": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_89.png",
  "seq_2_90": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_90.png",
  "seq_2_91": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_91.png",
  "seq_2_92": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_92.png",
  "seq_2_93": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_93.png",
  "seq_2_94": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_94.png",
  "seq_2_95": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_95.png",
  "seq_2_96": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_96.png",
  "seq_2_97": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_97.png",
  "seq_2_98": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_98.png",
  "seq_2_99": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_99.png",
  "seq_2_100": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_100.png",
  "seq_2_101": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_101.png",
  "seq_2_102": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_102.png",
  "seq_2_103": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_103.png",
  "seq_2_104": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_104.png",
  "seq_2_105": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_105.png",
  "seq_2_106": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_106.png",
  "seq_2_107": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_107.png",
  "seq_2_108": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_108.png",
  "seq_2_109": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_109.png",
  "seq_2_110": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_110.png",
  "seq_2_111": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_111.png",
  "seq_2_112": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_112.png",
  "seq_2_113": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_113.png",
  "seq_2_114": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_114.png",
  "seq_2_115": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_115.png",
  "seq_2_116": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_116.png",
  "seq_2_117": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_117.png",
  "seq_2_118": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_118.png",
  "seq_2_119": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_119.png",
  "seq_2_120": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_120.png",
  "seq_2_121": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_121.png",
  "seq_2_122": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_122.png",
  "seq_2_123": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_123.png",
  "seq_2_124": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_124.png",
  "seq_2_125": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_125.png",
  "seq_2_126": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_126.png",
  "seq_2_127": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_127.png",
  "seq_2_128": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_128.png",
  "seq_2_129": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_129.png",
  "seq_2_130": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_130.png",
  "seq_2_131": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_131.png",
  "seq_2_132": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_132.png",
  "seq_2_133": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_133.png",
  "seq_2_134": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_134.png",
  "seq_2_135": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_135.png",
  "seq_2_136": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_136.png",
  "seq_2_137": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_137.png",
  "seq_2_138": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_138.png",
  "seq_2_139": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_139.png",
  "seq_2_140": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_140.png",
  "seq_2_141": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_141.png",
  "seq_2_142": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_142.png",
  "seq_2_143": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_143.png",
  "seq_2_144": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_144.png",
  "seq_2_145": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_145.png",
  "seq_2_146": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_146.png",
  "seq_2_147": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_147.png",
  "seq_2_148": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_148.png",
  "seq_2_149": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_149.png",
  "seq_2_150": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_150.png",
  "seq_2_151": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_151.png",
  "seq_2_152": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_152.png",
  "seq_2_153": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_153.png",
  "seq_2_154": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_154.png",
  "seq_2_155": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_155.png",
  "seq_2_156": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_156.png",
  "seq_2_157": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_157.png",
  "seq_2_158": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_158.png",
  "seq_2_159": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_159.png",
  "seq_2_160": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_160.png",
  "seq_2_161": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_161.png",
  "seq_2_162": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_162.png",
  "seq_2_163": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_163.png",
  "seq_2_164": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_164.png",
  "seq_2_165": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_165.png",
  "seq_2_166": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_166.png",
  "seq_2_167": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_167.png",
  "seq_2_168": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_168.png",
  "seq_2_169": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_169.png",
  "seq_2_170": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_170.png",
  "seq_2_171": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_171.png",
  "seq_2_172": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_172.png",
  "seq_2_173": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_173.png",
  "seq_2_174": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_174.png",
  "seq_2_175": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_175.png",
  "seq_2_176": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_176.png",
  "seq_2_177": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_177.png",
  "seq_2_178": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_178.png",
  "seq_2_179": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_2_179.png",

  "seq_3_0": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_0.png",
  "seq_3_1": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_1.png",
  "seq_3_2": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_2.png",
  "seq_3_3": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_3.png",
  "seq_3_4": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_4.png",
  "seq_3_5": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_5.png",
  "seq_3_6": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_6.png",
  "seq_3_7": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_7.png",
  "seq_3_8": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_8.png",
  "seq_3_9": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_9.png",
  "seq_3_10": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_10.png",
  "seq_3_11": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_11.png",
  "seq_3_12": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_12.png",
  "seq_3_13": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_13.png",
  "seq_3_14": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_14.png",
  "seq_3_15": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_15.png",
  "seq_3_16": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_16.png",
  "seq_3_17": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_17.png",
  "seq_3_18": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_18.png",
  "seq_3_19": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_19.png",
  "seq_3_20": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_20.png",
  "seq_3_21": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_21.png",
  "seq_3_22": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_22.png",
  "seq_3_23": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_23.png",
  "seq_3_24": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_24.png",
  "seq_3_25": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_25.png",
  "seq_3_26": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_26.png",
  "seq_3_27": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_27.png",
  "seq_3_28": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_28.png",
  "seq_3_29": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_29.png",
  "seq_3_30": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_30.png",
  "seq_3_31": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_31.png",
  "seq_3_32": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_32.png",
  "seq_3_33": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_33.png",
  "seq_3_34": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_34.png",
  "seq_3_35": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_35.png",
  "seq_3_36": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_36.png",
  "seq_3_37": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_37.png",
  "seq_3_38": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_38.png",
  "seq_3_39": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_39.png",
  "seq_3_40": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_40.png",
  "seq_3_41": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_41.png",
  "seq_3_42": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_42.png",
  "seq_3_43": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_43.png",
  "seq_3_44": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_44.png",
  "seq_3_45": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_45.png",
  "seq_3_46": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_46.png",
  "seq_3_47": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_47.png",
  "seq_3_48": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_48.png",
  "seq_3_49": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_49.png",
  "seq_3_50": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_50.png",
  "seq_3_51": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_51.png",
  "seq_3_52": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_52.png",
  "seq_3_53": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_53.png",
  "seq_3_54": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_54.png",
  "seq_3_55": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_55.png",
  "seq_3_56": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_56.png",
  "seq_3_57": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_57.png",
  "seq_3_58": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_58.png",
  "seq_3_59": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_59.png",
  "seq_3_60": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_60.png",
  "seq_3_61": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_61.png",
  "seq_3_62": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_62.png",
  "seq_3_63": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_63.png",
  "seq_3_64": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_64.png",
  "seq_3_65": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_65.png",
  "seq_3_66": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_66.png",
  "seq_3_67": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_67.png",
  "seq_3_68": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_68.png",
  "seq_3_69": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_69.png",
  "seq_3_70": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_70.png",
  "seq_3_71": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_71.png",
  "seq_3_72": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_72.png",
  "seq_3_73": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_73.png",
  "seq_3_74": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_74.png",
  "seq_3_75": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_75.png",
  "seq_3_76": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_76.png",
  "seq_3_77": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_77.png",
  "seq_3_78": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_78.png",
  "seq_3_79": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_79.png",
  "seq_3_80": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_80.png",
  "seq_3_81": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_81.png",
  "seq_3_82": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_82.png",
  "seq_3_83": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_83.png",
  "seq_3_84": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_84.png",
  "seq_3_85": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_85.png",
  "seq_3_86": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_86.png",
  "seq_3_87": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_87.png",
  "seq_3_88": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_88.png",
  "seq_3_89": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_89.png",
  "seq_3_90": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_90.png",
  "seq_3_91": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_91.png",
  "seq_3_92": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_92.png",
  "seq_3_93": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_93.png",
  "seq_3_94": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_94.png",
  "seq_3_95": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_95.png",
  "seq_3_96": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_96.png",
  "seq_3_97": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_97.png",
  "seq_3_98": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_98.png",
  "seq_3_99": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_99.png",
  "seq_3_100": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_100.png",
  "seq_3_101": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_101.png",
  "seq_3_102": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_102.png",
  "seq_3_103": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_103.png",
  "seq_3_104": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_104.png",
  "seq_3_105": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_105.png",
  "seq_3_106": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_106.png",
  "seq_3_107": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_107.png",
  "seq_3_108": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_108.png",
  "seq_3_109": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_109.png",
  "seq_3_110": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_110.png",
  "seq_3_111": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_111.png",
  "seq_3_112": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_112.png",
  "seq_3_113": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_113.png",
  "seq_3_114": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_114.png",
  "seq_3_115": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_115.png",
  "seq_3_116": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_116.png",
  "seq_3_117": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_117.png",
  "seq_3_118": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_118.png",
  "seq_3_119": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_119.png",
  "seq_3_120": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_120.png",
  "seq_3_121": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_121.png",
  "seq_3_122": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_122.png",
  "seq_3_123": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_123.png",
  "seq_3_124": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_124.png",
  "seq_3_125": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_125.png",
  "seq_3_126": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_126.png",
  "seq_3_127": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_127.png",
  "seq_3_128": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_128.png",
  "seq_3_129": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_129.png",
  "seq_3_130": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_130.png",
  "seq_3_131": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_131.png",
  "seq_3_132": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_132.png",
  "seq_3_133": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_133.png",
  "seq_3_134": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_134.png",
  "seq_3_135": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_135.png",
  "seq_3_136": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_136.png",
  "seq_3_137": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_137.png",
  "seq_3_138": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_138.png",
  "seq_3_139": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_139.png",
  "seq_3_140": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_140.png",
  "seq_3_141": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_141.png",
  "seq_3_142": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_142.png",
  "seq_3_143": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_143.png",
  "seq_3_144": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_144.png",
  "seq_3_145": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_145.png",
  "seq_3_146": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_146.png",
  "seq_3_147": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_147.png",
  "seq_3_148": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_148.png",
  "seq_3_149": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_149.png",
  "seq_3_150": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_150.png",
  "seq_3_151": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_151.png",
  "seq_3_152": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_152.png",
  "seq_3_153": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_3_153.png",

  "seq_4_0": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_0.png",
  "seq_4_1": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_1.png",
  "seq_4_2": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_2.png",
  "seq_4_3": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_3.png",
  "seq_4_4": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_4.png",
  "seq_4_5": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_5.png",
  "seq_4_6": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_6.png",
  "seq_4_7": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_7.png",
  "seq_4_8": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_8.png",
  "seq_4_9": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_9.png",
  "seq_4_10": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_10.png",
  "seq_4_11": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_11.png",
  "seq_4_12": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_12.png",
  "seq_4_13": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_13.png",
  "seq_4_14": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_14.png",
  "seq_4_15": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_15.png",
  "seq_4_16": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_16.png",
  "seq_4_17": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_17.png",
  "seq_4_18": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_18.png",
  "seq_4_19": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_19.png",
  "seq_4_20": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_20.png",
  "seq_4_21": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_21.png",
  "seq_4_22": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_22.png",
  "seq_4_23": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_23.png",
  "seq_4_24": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_24.png",
  "seq_4_25": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_25.png",
  "seq_4_26": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_26.png",
  "seq_4_27": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_27.png",
  "seq_4_28": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_28.png",
  "seq_4_29": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_29.png",
  "seq_4_30": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_30.png",
  "seq_4_31": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_31.png",
  "seq_4_32": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_32.png",
  "seq_4_33": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_33.png",
  "seq_4_34": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_34.png",
  "seq_4_35": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_35.png",
  "seq_4_36": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_36.png",
  "seq_4_37": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_37.png",
  "seq_4_38": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_38.png",
  "seq_4_39": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_39.png",
  "seq_4_40": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_40.png",
  "seq_4_41": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_41.png",
  "seq_4_42": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_42.png",
  "seq_4_43": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_43.png",
  "seq_4_44": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_44.png",
  "seq_4_45": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_45.png",
  "seq_4_46": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_46.png",
  "seq_4_47": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_47.png",
  "seq_4_48": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_48.png",
  "seq_4_49": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_49.png",
  "seq_4_50": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_50.png",
  "seq_4_51": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_51.png",
  "seq_4_52": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_52.png",
  "seq_4_53": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_53.png",
  "seq_4_54": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_54.png",
  "seq_4_55": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_55.png",
  "seq_4_56": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_56.png",
  "seq_4_57": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_57.png",
  "seq_4_58": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_58.png",
  "seq_4_59": CDN_URL + "/image-assets/animation-assets/golden-ball/images/seq_4_59.png",
};

export default GoldenBallAssets;
