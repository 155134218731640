import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useSettingsService } from "@src/ducks/hooks";
import { selectedActiveAvatarWB } from "@src/ducks/slices/lobby.slice";
import { selectedProfileStatus } from "@src/ducks/slices/settings.slice";
import React from "react";
import { Image, Pressable, View } from "react-native";
import useStyles from "./styles.css";

const Avatar = () => {
  const styles = useStyles();
  const avatar = useAppSelector(selectedActiveAvatarWB);
  const { hudHeight } = React.useContext(DimensionContext);
  const hasProfileOpen = useAppSelector(selectedProfileStatus);
  const { onChangeSettings, onChangeProfile }  = useSettingsService();

  const onPress = () => {
    onChangeSettings(false);
    onChangeProfile(!hasProfileOpen);
  };

  return (
    <Pressable onPress={onPress} style={[styles.centerStyle, { width: hudHeight, height: hudHeight } ]} >
      <Image style={styles.i_avatar_bg} source={{uri: images.avatar_bg}} resizeMode="stretch" />
      <View style={[styles.v_mask_avatar, { width: hudHeight , height: hudHeight  }]}>
        <Image style={[styles.i_avatar, { width: hudHeight * 1.2, height: hudHeight * 1.82}]} 
          source={{uri: avatar}} resizeMode="stretch" />
      </View>
    </Pressable>
  );
};

export default Avatar;
