import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { GTM_AUTH, GTM_ID, GTM_PREVIEW } from "@env";
import { audio } from "@src/theme";
import FullScreenButton from "components/fullscreen-button";
import Portrait from "components/portrait";
import { Audio } from "expo-av";
import React, { useCallback, useEffect, useState } from "react";
import ErrorMessage from "./components/modals/error-sideway";
import SuccessTop from "./components/modals/success-top";
import { useAppSelector } from "./ducks/ducksHook";
import { selectedBackgroundSound } from "./ducks/slices/auth.slice";

const Sound = new Audio.Sound();

const gtmParams = {
  id: GTM_ID,
  environment: {
    gtm_auth: GTM_AUTH,
    gtm_preview: GTM_PREVIEW,
  },
};

interface AuthProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  handleBackgroundSound: (value: boolean) => void;
  handleClaimSound: () => void;
  handleClick: () => void;
  handleRewardSound: () => void;
  onErrorMessage: (value: string) => void;
  onSuccessMessage: (value: string) => void;
}

export const AuthContext = React.createContext<ContextValue>(
  {} as ContextValue
);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const bgSoundRef = React.useRef<any>(null);
  const clickRef = React.useRef<any>(null);
  const randomRef = React.useRef<any>(null);
  const claimRef = React.useRef<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const backgroundSound = useAppSelector(selectedBackgroundSound);

  const initiateSound = async () => {
    try {
      await Sound.loadAsync(audio.whoosh, {
        isLooping: true,
      });
      bgSoundRef.current = Sound;
      backgroundSound && bgSoundRef.current.playAsync();
    } catch (error) { /* empty */ }
  };

  const handleBackgroundSound = useCallback(async(value: boolean) => {
    if(value){
      bgSoundRef.current && bgSoundRef.current.playAsync();
    }else{
      bgSoundRef.current && bgSoundRef.current.pauseAsync();
    }
  }, [bgSoundRef.current]);

  const handleClick = useCallback(async() => {
    try {
      const click = new Audio.Sound();
      await click.loadAsync(audio.click);
      click.playAsync();

      if(backgroundSound){
        const playback = await bgSoundRef.current.getStatusAsync();
  
        if(playback.isPlaying === false && backgroundSound){
          bgSoundRef.current && bgSoundRef.current.playAsync();
        }
      }
    } catch (e) { console.log("eeee", e); }
  }, [backgroundSound, bgSoundRef.current]);

  const handleRewardSound = useCallback(async() => {
    try {
      const rewardRandom = new Audio.Sound();
      await rewardRandom.loadAsync(audio.reward_sound);
      rewardRandom.playAsync();
    } catch (e) { /* empty */ }
  }, []);

  const handleClaimSound = useCallback(async() => {
    try {
      const claimSound = new Audio.Sound();
      await claimSound.loadAsync(audio.claim);
      claimSound.playAsync();
    } catch (e) { /* empty */ }
  }, []);

  const onErrorMessage = useCallback(setError, [error]);
  const onSuccessMessage = useCallback(setSuccess, [success]);

  useEffect(() => {
    if(backgroundSound){
      const timeout = setTimeout(() => {
        handleBackgroundSound(true);
        clearTimeout(timeout);
      },600);
    }else{
      const timeout = setTimeout(() => {
        handleBackgroundSound(false);
        clearTimeout(timeout);
      },600);
    }
  },[backgroundSound]);

  useEffect(() => {
    initiateSound();

    return bgSoundRef.current ? () => {
      bgSoundRef.current.unloadAsync();
      clickRef.current.unloadAsync();
      randomRef.current.unloadAsync();
      claimRef.current.unloadAsync();
    } : undefined;
  }, []);

  return (
    <AuthContext.Provider value={{ handleBackgroundSound, handleClaimSound, handleClick, handleRewardSound, onErrorMessage, onSuccessMessage }}>
      <GTMProvider state={gtmParams}>
        {children}
      </GTMProvider>
      <FullScreenButton />
      <Portrait />
      <ErrorMessage
        message={error}
        visible={error !== null}
        onClose={() => setError(null)}
      />
      <SuccessTop
        message={success}
        visible={success !== null}
        onClose={() => setSuccess(null)}
      />
    </AuthContext.Provider>
  );
};

export default AuthProvider;
