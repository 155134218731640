import React from "react";
import { selectedNotificationsUnread } from "@src/ducks/slices/lobby.slice";
import { DimensionContext } from "@src/DimensionProvider";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { ImageButton02 } from "components/button";
import { View, Image } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";

const Email = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { hudHeight } = React.useContext(DimensionContext);
  const hasRead = useAppSelector(selectedNotificationsUnread);

  return (
    <ImageButton02
      onPress={() => navigation.navigate("Inbox")}
      style={[styles.container, 
        { width:  hudHeight * 0.7830, height: hudHeight * 0.5997, marginTop: hudHeight * 0.01 }]}
    >
      <View style={[styles.dotStyle,
        { width: hudHeight * 0.1, 
          height: hudHeight * 0.1, 
          top: -(hudHeight * 0.05), 
          right: -(hudHeight * 0.05)  }, 
        hasRead && styles.dotActiveStyle]} />
      <Image
        source={{uri: images.btn_mail}}
        style={styles.size_egg}
        resizeMode="stretch"
      />
    </ImageButton02>
  );
};

export default Email;
