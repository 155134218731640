import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useSettingsService } from "@src/ducks/hooks";
import { selectedSettingsStatus } from "@src/ducks/slices/settings.slice";
import { ImageButton } from "components/button";
import React from "react";

const Settings = () => {
  const { onChangeSettings }  = useSettingsService();
  const { hudHeight } = React.useContext(DimensionContext);
  const onPress = () => onChangeSettings(!hasSettingsOpen);
  const hasSettingsOpen = useAppSelector(selectedSettingsStatus);

  return (
    <ImageButton
      onPress={onPress}
      source={{uri: images.btn_settings}}
      style={[{ width: hudHeight * 0.724, height: hudHeight * 0.725 }]}
    />
  );
};

export default React.memo(Settings);
