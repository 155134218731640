import { colors } from "@src/theme";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 10,
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.black3,
    },
    imageBorderStyle: {
      width: 140,
      height: 140,
    },
    p_rotate_style: {
      width: 80,
      height: 80,
    },
    t_loading: {
      fontSize: 16,
      color: colors.white,
      marginVertical: "6%",
      fontFamily: "Roboto",
    },
    btnLogin: { height: 80, width: "36%", marginTop: "5%" },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      btnLogin: {
        height: 65,
      },
    },
  }
);

export default useStyles;
