import { DimensionContext } from "@src/DimensionProvider";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

type Props = {
  source: any;
  amount: string;
}

const ItemGold = ({ source, amount }: Props) => {
  const styles = useStyles();
  const { hudHeight } = React.useContext(DimensionContext);

  return (
    <View style={styles.v_item_gold}>
      <Image
        resizeMode="stretch"
        source={source}
        style={styles.i_gold}
      />
      <Text style={[styles.t_gold_amount, 
        { fontSize: hudHeight * 0.12}]}>{amount}</Text>
    </View>
  );
};

export default ItemGold;
