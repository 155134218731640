import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useForgotService } from "@src/ducks/hooks";
import React from "react";
import { ActivityIndicator, Image, Text, TouchableOpacity, View } from "react-native";
import useStyles from "./styles.css";

const CheckEmail = ({ email }: any) => {
  const styles = useStyles();
  const {onForgot, isLoading}= useForgotService();
  const { baseWidth, maxHeight } = React.useContext(DimensionContext);

  const onSubmit = () => {
    onForgot(email);
  };

  return (
    <View style={[styles.v_check_email, { height: maxHeight * 0.5}]}>
      <Image
        source={{uri: images.bg_transparent}}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <Image
        source={{uri: images["gamezone-logo-horizontal"]}}
        style={styles.smallLogoContainer}
        resizeMode="stretch"
      />
      <View style={[styles.viewEmail]}>
        <View style={[styles.viewForgotStyle]}>
          <Image
            resizeMode="contain"
            source={{uri: images["text-check-email"]}}
            style={styles.forgotTextStyle}
          />
        </View>
        <Text style={[styles.txtEmailNote, { fontSize: baseWidth * 0.013}]}>
          We've sent password reset instructions to:
        </Text>
        <Text style={[[styles.txtEmailNote, { fontSize: baseWidth * 0.013}], { fontFamily: "Roboto-Bold" }]}>
          {email}
        </Text>
        <Text style={[styles.txtEmailNote, { fontSize: baseWidth * 0.013}]}>
          If it doesn't arrive soon, check your spam folder or
        </Text>

        {isLoading ? (
          <ActivityIndicator
            style={{ marginTop: 10 }}
            size={20}
            color="white"
            animating
          />
        ) : (
          <TouchableOpacity style={{ padding: 10 }} onPress={onSubmit}>
            <Text
              style={[styles.txtEmailNote, { textDecorationLine: "underline", fontSize: baseWidth * 0.013 }]}
            >
              send the email again.
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default CheckEmail;
