import SVGSettings from "@assets/svg/settings";
import React from "react";
import { View } from "react-native";
import Switch from "./Switch";
import useStyles from "./styles.css";

export default ({baseWidth, baseHeight}: any) => {
  const styles = useStyles();
  // const onShare = () => Linking.openURL("https://www.facebook.com/Fortune8GWz");

  return (
    <View style={[styles.v_fbandsound]}>
      {/* <ImageButton05 scaleSize={0.98}>
        <SVGSettings name="btn-share" width={baseWidth * 0.2773} height={baseHeight * 0.086} />
      </ImageButton05> */}
      <View style={[styles.v_sound, { width: baseWidth * 0.3867, height: baseHeight * 0.086, paddingRight: baseWidth * 0.03}]}>
        <View style={{position: "absolute"}}>
          <SVGSettings name="bg-sound" width={baseWidth * 0.3867} height={baseHeight * 0.086} />
        </View>
        <Switch />
      </View>
    </View>
  );
};
