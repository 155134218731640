import images from "@assets/images";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedSigningSuccess } from "@src/ducks/slices/signup.slice";
import React from "react";
import { ImageBackground } from "react-native";
import EnterFields from "./enter-fields";
import SignupCompleted from "./signup-completed";
import useStyles from "./styles.css";

const Signup = () => {
  const styles = useStyles();
  const isSignIn = useAppSelector(selectedSigningSuccess);

  return (
    <ImageBackground
      source={{uri: images["gamezone-bg"]}}
      style={[styles.container]}
      resizeMode="stretch"
    >
      {isSignIn ? <SignupCompleted /> : <EnterFields />}
    </ImageBackground>
  );
};

export default Signup;
