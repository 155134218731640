import LoadingProvider from "components/loading/LoadingProvider";
import Splash from "components/splash";
import SplashBg from "components/splash/splash-bg";
import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { enableFreeze, enableScreens } from "react-native-screens";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BreakpointsProvider } from "rn-responsive-styles";
import AuthProvider from "./AuthProvider";
import DimensionProvider from "./DimensionProvider";
import store, { persistor } from "./ducks/store";
import AppNavigator from "./navigators/AppNavigator";
import CoinProvider from "./screens/home/main/CoinProvider";
import ErrorPopup from "./screens/popups/pop-notice";

enableFreeze(true);
enableScreens(true);

const App = () => {
  const [appIsReady, setAppIsReady] = React.useState(false);

  return (
    <Provider store={store}>
      <PersistGate loading={<SplashBg />} persistor={persistor}>
        <BreakpointsProvider breakpoints={[1680, 1025, 768, 540]}>
          <SafeAreaProvider>
            <AuthProvider>
              <DimensionProvider>
                <CoinProvider>
                  <LoadingProvider>
                    {appIsReady ? <>
                      <AppNavigator />
                      <ErrorPopup />
                    </> : <Splash onComplete={() => setAppIsReady(true)} />}
                  </LoadingProvider>
                </CoinProvider>
              </DimensionProvider>
            </AuthProvider>
          </SafeAreaProvider>
        </BreakpointsProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
