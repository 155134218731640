import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    btn_style: { 
      zIndex: 5,
      borderRadius: 1000, 
      bottom: "11%",
      marginLeft: "6%",
    },
  },
);

export default useStyles;
