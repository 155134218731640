import { CreateResponsiveStyle } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import colors from "theme/colors";

const useStyles = CreateResponsiveStyle(
  {
    main: {
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.black1,
    },
    container: {
      zIndex: 100,
      width: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "hidden",
      alignItems: "center",
      justifyContent: "center",
    },
    ib_style: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    bodyStyle: {
      width: "55%",
      height: "87%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
);

export default useStyles;
