import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyStyle: {
      width: "58%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    smallLogoContainer: {
      height: "48%",
      width: "80%",
      top: "-25%",
      position: "absolute",
    },
    v_center: {
      width: "92.5%",
      height: "90%",
      marginBottom: "2%",
      marginRight: "2%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    v_center_reset: {
      width: "92.7%",
      height: "80%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    bottomHeight: {
      width: 100,
      height: "18%",
    },
    header: {
      zIndex: 1,
      fontSize: 30,
      paddingTop: 48,
      color: "#6C0B7B",
      marginBottom: 40,
      fontFamily: "Roboto-Medium",
    },

    // ENTER EMAIL COMPONENT STYLE
    viewForgotStyle: {
      width: "65%",
      height: "21%",
      alignItems: "center",
      justifyContent: "center",
    },
    forgotTextStyle: { width: "100%", height: 60 },
    viewEmail: {
      width: "93%",
      height: "80%",
      alignItems: "center",
      justifyContent: "center",
    },
    txtEmailNote: {
      fontSize: 16,
      marginTop: "2%",
      textAlign: "center",
      color: colors.white,
      fontFamily: "Roboto",
    },
    inputEmailStyle: { marginTop: 30, height: "15%" },
    height20: { height: 20 },
    btnSubmit: { height: "18%", width: "20%", marginTop: "3%" },
    btnClose: {
      position: "absolute",
      zIndex: 1,
      top: "20%",
      right: "-35%",
    },
    imageCloseStyle: { height: 30, width: 30 },
    textAlign: { textAlign: "center", paddingLeft: 0, fontSize: 16 },

    // RESET PASSWORD
    ti_reset: { marginTop: "3%", height: "14%" },
    btn_reset_password: { height: "20%", width: "24%", marginTop: "3%" },

    // SUCCESS
    i_success: { width: "45%", height: "16%" },
    v_logo: {
      width: "22%",
      height: "30%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_fortune_logo: {
      width: "100%",
      height: "100%",
    },
  },
);

export default useStyles;
