import React, { useEffect } from "react";
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withSequence,
  withTiming,
} from "react-native-reanimated";
import images from "@assets/images";

const Star = (props: any) => {
  const animated = useSharedValue(0);

  const animateStyle = useAnimatedStyle(() => {
    return {
      transform: [{ scale: animated.value }],
    };
  }, []);

  useEffect(() => {
    const scaleSequence = withDelay(
      props.delay || 3000,
      withTiming(props.scale || 1.1, { duration: props.duration || 500, easing: Easing.linear })
    );
    const sequence = withSequence(
      scaleSequence,
      withTiming(0.05, { duration: props.duration || 500, easing: Easing.linear }),
    );
  
    animated.value = withRepeat(sequence, -1, true);
  }, [props.scale, props.duration,  props.delay]);

  return (
    <Animated.Image style={[props.style, animateStyle]} source={{uri: images.star}} resizeMode={"contain"}  />
  );
};

export default Star;
