import images from "@assets/images";
import SVGIcon from "@assets/svg";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { selectedServerTime } from "@src/ducks/slices/lobby.slice";
import { ImageButton02 } from "components/button";
import React from "react";
import { Image, View } from "react-native";
import CountDown from "./count-down";
import useStyles from "./styles.css";

const Bonus = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { onServerTime } = useLobbyService();
  const dailyTimer = useAppSelector(selectedServerTime);
  const { baseWidth, hudHeight } = React.useContext(DimensionContext);

  return (
    <>
      <ImageButton02
        onPress={() => navigation.navigate("DailyBonus")}
        disabled={dailyTimer > 0}
        style={[styles.container, {
          width: hudHeight * 1.26, 
          height: hudHeight * 1.26, 
          borderRadius: hudHeight,
          left: baseWidth * 0.0856, 
          bottom: 0
        }]}
      >
        {dailyTimer > 0 && <View style={styles.imageStyle} >
          <SVGIcon name="bonus-button"  width={hudHeight * 1.26} height={hudHeight * 1.26}  />
        </View>}
        <Image 
          style={[styles.imageStyle, dailyTimer > 0 && styles.image_inactive]} 
          source={{uri: images.btn_bonus}} 
          resizeMode="stretch" />
        
        {dailyTimer > 0 && <CountDown onDone={() => onServerTime(0)}  dailyTimer={dailyTimer} baseHeight={hudHeight} />}
      </ImageButton02>
    </>
  );
};

export default Bonus;
