import SVGSettings from "@assets/svg/settings";
import Icon from "@expo/vector-icons/Ionicons";
import { DimensionContext } from "@src/DimensionProvider";
import { useSettingsService } from "@src/ducks/hooks";
import { colors } from "@src/theme";
import { ImageButton05 } from "components/button";
import React from "react";
import { TouchableOpacity, View } from "react-native";
import Logout from "./Logout";
import Policy from "./Policy";
import Sound from "./Sound";
import Terms from "./Terms";
import useStyles from "./styles.css";

const Settings = ({ style, onBack }: any) => {
  const styles = useStyles();
  const { onChangeSettings } = useSettingsService();
  const { hudHeight, baseWidth, baseHeight } = React.useContext(DimensionContext);
  const size = React.useMemo(() => {
    return {
      width: baseWidth * 0.3228,
      height: baseHeight * 1,
      top: hudHeight * 0.7
    };
  },[hudHeight, baseHeight, baseWidth]);

  return (
    <View style={[styles.container, size, style]}>
      <View style={{position: "absolute" }}>
        <SVGSettings name="bg-settings" width={size.width} height={size.height} />
      </View>
      <View style={styles.v_settings}>
        <View style={{height: size.height * 0.09}} />
        <Sound baseWidth={size.width} baseHeight={size.height} />
        <View style={{height: size.height * 0.04}} />
        <Terms baseWidth={size.width} baseHeight={size.height} />
        <View style={{height: size.height * 0.04}} />
        <Policy baseWidth={size.width} baseHeight={size.height} />
        <View style={{height: size.height * 0.04}} />
        <Logout baseWidth={size.width} baseHeight={size.height} />
        <ImageButton05 scaleSize={0.98} style={styles.btn_faq} >
          <SVGSettings name="btn-faq" width={size.width * 0.2148} height={size.height * 0.1903} />
        </ImageButton05>
        <TouchableOpacity
          onPress={() => (onBack ? onBack() : onChangeSettings(false))}
          style={styles.btn_back} >
          <Icon size={size.width * 0.11} color={colors.gray1} name="chevron-up-outline" />
        </TouchableOpacity>
      </View>
      <View style={styles.v_bottom} />
    </View>
  );
};

export default React.memo(Settings);
