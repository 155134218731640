import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import colors from "theme/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      height: "90%",
      position: "absolute",
      alignItems: "center",
    },
    bodyStyle: {
      width: "94%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_bg: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    v_bg: {
      width: "100%",
      height: "94%",
      alignItems: "center",
      // backgroundColor: 'red'
    },
    v_center_tier: { width: "100%", height: "100%", alignItems: "center" },
    i_tier_rewards: {
      top: "24%",
      width: "34%",
      height: "10%",
      position: "absolute",
    },
    v_tier_banner: {
      top: "34%",
      width: "95%",
      height: "55%",
      flexDirection: "row",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    v_tier_level: {
      width: "20%",
      height: "100%",
      alignItems: "center",
    },
    i_tier_frame: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    t_tier_label: {
      fontFamily: "Roboto-Bold",
      color: colors.white,
      fontSize: 20,
      top: "7%",
    },
    t_game_access: {
      fontFamily: "Inter-Bold",
      textAlign: "center",
      paddingHorizontal: "10%",
      color: colors.orange1,
      fontSize: 20,
    },
    t_tier_notes: {
      fontFamily: "Roboto",
      textAlign: "center",
      paddingHorizontal: "20%",
      color: colors.white,
      fontSize: 20,
    },
    i_tier_trophy: {
      width: "75%",
      height: "63%",
    },
    i_tier_banner: {
      width: "21%",
      height: "100%",
    },
    i_tier_arrow: {
      width: "6%",
      bottom: "10%",
      height: "10%",
    },

    btn_back: {
      zIndex: 5,
      bottom: 0,
      width: "23%",
      height: "14%",
      marginRight: 15,
      position: "absolute",
    },
  },

  {
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
