import React from "react";
import { View, ActivityIndicator, StyleSheet, Platform } from "react-native";

const Loading02 = (props: any) => {
  const size = Platform.OS === "web" ? "large" : "small";

  return (
    <View style={[styles.container, props.style]}>
      <ActivityIndicator animating size={size} color={props.color || "black"} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 60,
    alignItems: "center",
  },
});

export default Loading02;
