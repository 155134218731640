import React, { useState, createContext } from "react";

const AvatarContext = createContext<any>({});

const AvatarProvider = ({ children }: any) => {
  const [showScreen, setShowScreen] = useState("");

  return (
    <AvatarContext.Provider value={{ showScreen, setShowScreen }}>
      {children}
    </AvatarContext.Provider>
  );
};

export { AvatarProvider, AvatarContext };
