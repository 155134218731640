import React from "react";
import { SvgProps } from "react-native-svg";

//SVGs
import BonusButton from "./bonus-button.svg";
import Close from "./close.svg";
import FrameFootball from "./frame-football.svg";
import Inbox from "./inbox.svg";
import SignupBonus from "./signup-bonus.svg";
import TextFillFootball from "./text-football.svg";
import Welcome from "./welcome.svg";

export interface IconProps extends SvgProps {
  name: "back" | "next" | "camera" | "document" | "addFill" | any;
  size?: number;
  stroke?: string;
  fill?: string;
  isActive?: boolean;
}

const SVGIcon = (props: IconProps) => {
  const { name, width, height } = props;
  switch (name) {
  case "text-football":
    return <TextFillFootball width={width || 144} height={height || 42} {...props} />;
  case "frame-football":
    return <FrameFootball width={width || 235} height={height || 62} {...props} />;
  case "bonus-button":
    return <BonusButton width={width || 235} height={height || 62} {...props} />;
  case "inbox":
    return <Inbox width={width || 235} height={height || 62} {...props} />;
  case "close":
    return <Close width={width || 235} height={height || 62} {...props} />;
  case "welcome":
    return <Welcome width={width || 235} height={height || 62} {...props} />;
  case "signup-bonus":
    return <SignupBonus width={width || 235} height={height || 62} {...props} />;
  default:
    return null;
  }
};

export default React.memo(SVGIcon);
