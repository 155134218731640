import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedHourlyReward } from "@src/ducks/slices/lobby.slice";
import numeral from "numeral";
import React, { useEffect, useRef, useState } from "react";
import { Text } from "react-native";
import useStyles from "./styles.css";

const Timer = ({ baseHeight }: any) => {
  const styles = useStyles();
  const timerId = useRef<any>(null);
  const [timer, setTimer] = useState(0);
  const checker = useAppSelector(selectedHourlyReward);

  useEffect(() => {
    setTimer(checker.timer);
  }, [checker]);

  useEffect(() => {
    if (!timer) {
      timerId.current && clearInterval(timerId.current);
      return;
    }

    timerId.current = setInterval(() => {
      setTimer((lastTimerCount) => lastTimerCount - 1);
    }, 1000);

    return () => clearInterval(timerId.current);
  }, [timer]);

  return (
    <Text allowFontScaling style={[styles.goldText, { fontSize: baseHeight * 0.11}]}>
      0{numeral(timer).format("00:00:00")}
    </Text>
  );
};

export default React.memo(Timer);
