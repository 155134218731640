import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const Disclaimer = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseWidth } = React.useContext(DimensionContext);

  return (
    <View style={styles.container}>
      <View style={[styles.bodyStyle, 
        { width: baseWidth * 0.73, height: baseWidth * 0.4142 },
      ]}>
        <Image
          source={{uri: images.bg_transparent}}
          style={styles.imageBorderStyle}
          resizeMode="stretch"
        />
        <View style={styles.v_center}>
          <View style={[styles.viewForgotStyle]}>
            <Text style={[styles.t_title, 
              { fontSize: baseWidth * 0.035}]}>DISCLAIMER 
            </Text>
          </View>
          <View style={styles.viewEmail}>
            <Text style={[styles.t_description, { fontSize: baseWidth * 0.018}]}>
            Welcome to Game Zone. Please enjoy our range of new social games available online. You must be 18+ years and older and be aware of the following.{"\n\n"}
            The games contained in Game Zone does not offer gambling and in no way offer any real money wins, real prizes or equivalent. Game Zone is strictly a social gaming application and our game mechanics do not promise success in any gambling in the future. 
            </Text>
          </View>
          <View style={styles.v_button}>
            <ImageButton06
              onPress={() => navigation.goBack()}
              style={[
                styles.btnSubmit,
              ]}
              label="CLOSE"
              source={images["btn-big"]}
              labelStyle={{fontSize: baseWidth * 0.013}}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default Disclaimer;
