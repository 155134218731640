import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedGModeVisit } from "@src/ducks/slices/gmode.slice";
import { selectedLobbySuccess, selectedMissionHasKeys } from "@src/ducks/slices/lobby.slice";
import { selectedTierUpgradeBonuses } from "@src/ducks/slices/tier.slice";
import { selectedSilverCoins, selectedUserNewUser } from "@src/ducks/slices/user.slice";
import _ from "lodash";
import React, { createContext, useCallback, useState } from "react";
import { schedulePopup } from "utils/filter-helper";

interface ContextValue {
  showcurtain: boolean;
  onShowCurtain: (value: boolean) => void;
}

export const HomeContext = createContext<ContextValue>({
  showcurtain: false,
} as ContextValue);

interface HomeProviderProps {
  children: React.ReactElement;
}

const HomeProvider = ({ children }: HomeProviderProps) => {
  const navigation = useNavigation<any>();
  const hasPlayGame = useAppSelector(selectedGModeVisit);
  const userRewards = useAppSelector(selectedUserNewUser);
  const isHomeLoaded = useAppSelector(selectedLobbySuccess);
  const missionKeys = useAppSelector(selectedMissionHasKeys);
  const [showcurtain, setShowCurtain] = useState<boolean>(false);
  const isLowSilverBalance = useAppSelector(selectedSilverCoins);
  const unclaimedBonuses = useAppSelector(selectedTierUpgradeBonuses);

  const onShowCurtain = useCallback((value: boolean) => {
    setShowCurtain(value);
  },[showcurtain]);

  React.useEffect(() => {
    if (!_.isEmpty(userRewards) && isHomeLoaded) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        navigation.navigate("WelcomeBonus");
      }, 1000);
    }

    if (_.isEmpty(userRewards) && isHomeLoaded) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        const popup = schedulePopup();
        navigation.navigate(popup);
      }, 1000);
    }
  }, [isHomeLoaded]);

  React.useEffect(() => {
    if (hasPlayGame && isLowSilverBalance) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        navigation.navigate("PremiumPackage");
      }, 1000);
    }
  }, [isLowSilverBalance, hasPlayGame]);

  React.useEffect(() => {
    if (missionKeys) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        navigation.navigate("MissionKey");
      }, 1000);
    }
  }, [missionKeys]);

  React.useEffect(() => {
    if (unclaimedBonuses?.length) {
      const bonus = unclaimedBonuses[0];
      if (bonus) {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          navigation.navigate("TierLevelUp", {
            bonus
          });
        }, 1000);
      }
    }
  }, [unclaimedBonuses]);

  return (
    <HomeContext.Provider value={{ showcurtain, onShowCurtain }}>
      {children}
    </HomeContext.Provider>
  );
};

export default HomeProvider;
