import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { PAYPAL_CLIENT_ID } from "@env";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { StackActions, useNavigation, useRoute } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAuthSession } from "@src/ducks/slices/auth.slice";
import { selectedPurchaseData, selectedPurchaseLoad } from "@src/ducks/slices/lobby.slice";
import { selectedUserEmail } from "@src/ducks/slices/user.slice";
import { Loading01 } from "components/loading";
import _ from "lodash";
import React, { useEffect } from "react";
import { ScrollView, useWindowDimensions } from "react-native";
import { CoinContext } from "../home/main/CoinProvider";
import ApcopayButton from "./apcopay-button";
import BitpaceButton from "./bitpace/bitpace-button";
import PaypalButtons from "./paypal-buttons";
import { PaymentContext } from "./provider";

const initialOptions: any = {
  currency: "USD",
  intent: "capture",
  "client-id": PAYPAL_CLIENT_ID,
};

const PaymentOption = () => {
  const route = useRoute<any>();
  const sendDataToGTM = useGTMDispatch();
  const navigation = useNavigation<any>();
  const { width } = useWindowDimensions();
  const amount = route?.params?.amount;
  const isJadeEgg = route?.params?.isJadeEgg;
  const session = useAppSelector(selectedAuthSession);
  const { loading } = React.useContext(PaymentContext);
  const emailAddress = useAppSelector(selectedUserEmail);
  const isLoading = useAppSelector(selectedPurchaseLoad);
  const paypalSuccess = useAppSelector(selectedPurchaseData);
  const { onAdditionalCurrency } = React.useContext(CoinContext);

  useEffect(() => {
    if (!_.isEmpty(paypalSuccess)) {
      sendDataToGTM({
        event: "purchase",
        ecommerce: {
          transaction_id: paypalSuccess.transactionNo,
          user_id: session.userId,
          email: emailAddress,
          value: paypalSuccess.amount,
          currency: paypalSuccess.currency,
          paymentTypeID: paypalSuccess.paymentTypeID,
          tax: 0,
          shipping: 0,
          items: [
            {
              item_id: paypalSuccess.data.id,
              item_name: paypalSuccess.purchaseItem,
              currency: paypalSuccess.data.currency,
              price: paypalSuccess.data.amount,
              paymentTypeID: paypalSuccess.paymentTypeID,
              quantity: 1,
            },
          ],
        },
      });

      if (isJadeEgg) {
        navigation.goBack();
      } else {
        navigation.dispatch(StackActions.replace("PaymentConfirm", route?.params));
        onAdditionalCurrency({silver: route?.params?.silver, bg: route?.params?.gold ?? 0, gwz: 0});
      }
    }
  }, [paypalSuccess]);

  return (
    <>
      <ScrollView style={{ flex: 1, width: width * 0.5 }}>
        {amount > 10 && <BitpaceButton />}
        <ApcopayButton />
        <PayPalScriptProvider options={initialOptions}>
          <PaypalButtons />
        </PayPalScriptProvider>
      </ScrollView>
      <Loading01 isLoading={isLoading || loading} color="black" />
    </>
  );
};

export default React.memo(PaymentOption);
