import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const TierLevelReward = ({ onPress }: any) => {
  const styles = useStyles();
  const { baseWidth, maxHeight } = React.useContext(DimensionContext);

  return (
    <View style={styles.container2}>
      <View style={[styles.bodyStyle,
        { width: baseWidth * 0.73, height: baseWidth * 0.4142 },
      ]}>
        <Image
          source={{uri: images.bg_transparent}}
          style={styles.imageBorderStyle}
          resizeMode="stretch"
        />
        <View style={styles.v_center}>
          <View style={[styles.viewForgotStyle]}>
            <Text style={[styles.t_title, 
              { fontSize: baseWidth * 0.035}]}>TIER LEVEL REWARDS 
            </Text>
          </View>
          <View style={styles.viewEmail}>
            <Text style={[styles.t_description, { fontSize: baseWidth * 0.018}]}>
            We hope you are enjoying your time playing our games and as we love to give our users lots of bonuses and rewards we have applied Tier rewards for all of you. {"\n\n"}
            There are plenty of games for you to choose from to play for free. As a social gaming platform these games will not give you real money or prizes. {"\n\n"}
            Tier Rewards is our way of giving back for your spend. This feature is enabled on all accounts and you will automatically move up a tier depending on your account activity. 
            </Text>
          </View>
          <View style={styles.v_button}>
            <ImageButton06
              onPress={onPress}
              style={[styles.btnSubmit, { height: maxHeight * 0.07}]}
              label="READ MORE"
              source={images["btn-big"]}
              labelStyle={{fontSize: baseWidth * 0.012}}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default TierLevelReward;
