import React, { useRef, useEffect } from 'react';
import { Animated, Easing, Image } from 'react-native';
const AnimatedImage = Animated.createAnimatedComponent(Image);

const RotateAnimated = ({ style, source, outputRange }: any) => {
  const animated = useRef(new Animated.Value(0)).current;

  const handleAnimation = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(animated, {
          toValue: 1,
          duration: 5000,
          easing: Easing.linear,
          useNativeDriver: false,
        }),
        Animated.timing(animated, {
          toValue: 0,
          duration: 5000,
          easing: Easing.linear,
          useNativeDriver: false,
        }),
      ])
    ).start();
  };

  const rotate = animated.interpolate({
    inputRange: [0, 1],
    outputRange: outputRange || ['0deg', '360deg'],
  });

  useEffect(() => {
    handleAnimation();
  }, []);

  return (
    <AnimatedImage
      style={[
        style,
        {
          transform: [
            {
              rotate,
            },
          ],
        },
      ]}
      source={source}
      resizeMode="stretch"
    />
  );
};

export default RotateAnimated;
