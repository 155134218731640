import React from "react";
import { selectedSettingsStatus } from "@src/ducks/slices/settings.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useSettingsService } from "@src/ducks/hooks";
import { TouchableOpacity } from "react-native";
import { Height } from "utils/animation";
import useStyles from "./styles.css";
import Settings from "./Settings";

const SettingScreen = () => {
  const styles = useStyles();
  const { onChangeSettings } = useSettingsService();
  const hasSettingsOpen = useAppSelector(selectedSettingsStatus);

  return (
    <Height style={styles.v_cover} toValue={hasSettingsOpen ? 1 : 0}>
      <TouchableOpacity
        style={styles.flex1}
        onPress={() => onChangeSettings(false)} />
      <Settings />
    </Height>
  );
};

export default React.memo(SettingScreen);
