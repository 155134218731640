import SVGSettings from "@assets/svg/settings";
import { useNavigation } from "@react-navigation/native";
import { ImageButton05 } from "components/button";
import React from "react";
import { View } from "react-native";
import useStyles from "./styles.css";

const Terms = ({baseWidth, baseHeight}: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();

  // const onTerms = () =>
  //   Linking.openURL("https://fortune8.online/web-terms-%2B-conditions");

  return (
    <View style={styles.v_terms}>
      <ImageButton05 scaleSize={0.98} >
        <SVGSettings name="btn-terms-condition" width={baseWidth * 0.4375} height={baseHeight * 0.083} />
      </ImageButton05>
      <ImageButton05 scaleSize={0.98} onPress={() => navigation.navigate("TierLevel")} >
        <SVGSettings name="btn-tier-level" width={baseWidth * 0.3828} height={baseHeight * 0.083} />
      </ImageButton05>
    </View>
  );
};

export default Terms;
