import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { selectedGamesOrigin } from "@src/ducks/slices/lobby.slice";
import { ImageButton } from "components/button";
import React from "react";
import { compileTransformation, goldGamesFilter } from "utils/transform-helper";
import { HomeContext } from "../../main/HomeProvider";

const GameSwitch = () => {
  const { onChangeGames } = useLobbyService();
  const games = useAppSelector(selectedGamesOrigin);
  const [gold, setGoldStatus] = React.useState(false);
  const { hudHeight } = React.useContext(DimensionContext);
  const { showcurtain, onShowCurtain} = React.useContext(HomeContext);

  const onPress = async (value: boolean) => {
    setGoldStatus(value);
    onShowCurtain(!showcurtain);
    onChangeGames({transform: [], isGoldGames: value});
  };

  React.useEffect(() => {
    if(games.length > 0){
      const timeout = setTimeout(async() => {
        const newgames = gold ? goldGamesFilter(games) : games;
        const transform = await compileTransformation(newgames);
        onChangeGames({transform, isGoldGames: gold });
        clearTimeout(timeout);
      },1000);
    }
  },[gold]);


  return (
    <ImageButton
      source={gold ? images.btn_switch_gold : images.btn_switch_silver}
      onPress={() => onPress(!gold)}
      style={[{ width: hudHeight * 0.845, height: hudHeight * 0.845 }]}
    />
  );
};

export default React.memo(GameSwitch);
