import React from "react";
import { Image, TouchableOpacity, StyleSheet } from "react-native";

const ImageButton = (props: any) => {
  const {
    style,
    source,
    onPress,
    disabled,
    onLongPress,
    activeOpacity,
    currentIndex,
  } = props;
  const longRef = React.useRef<any>(null);
  const count = React.useRef<any>(currentIndex);

  const onLong = () => {
    longRef.current = setInterval(() => {
      count.current += 1;
      onLongPress(count.current);
    }, 400);
  };

  React.useEffect(() => {
    count.current = currentIndex;

    if (disabled) {
      longRef.current && clearInterval(longRef.current);
    }
  }, [currentIndex, disabled]);

  if (disabled) {
    return null;
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      onLongPress={onLong}
      onPressOut={() => {
        longRef.current && clearInterval(longRef.current);
      }}
      activeOpacity={activeOpacity}
      style={[styles.buttonStyle, style]}
    >
      <Image
        source={source}
        resizeMode="stretch"
        style={styles.imageButtonStyle}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    width: "40%",
    height: 70,
    alignItems: "center",
    justifyContent: "center",
  },
  imageButtonStyle: {
    width: "100%",
    height: "100%",
  },
});

export default ImageButton;
