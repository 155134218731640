import images from "@assets/images";
import { ImageButton06 } from "components/button";
import React from "react";
import useStyles from "./styles.css";

interface FooterProps {
  onPress?: () => void;
  baseWidth: number;
}

const Footer = ({ onPress, baseWidth }: FooterProps) => {
  const styles = useStyles();

  return (
    <ImageButton06
      onPress={onPress}
      style={[styles.btnLogin]}
      label="LOG IN"
      source={images["btn-big"]}
      labelStyle={{fontSize: baseWidth * 0.013}}
    />
  );
};

export default Footer;
