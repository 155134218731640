import React from "react";
import { View } from "react-native";
import Star from "./star";

function SprinkerStar({WIDTH = 300, HEIGHT = 300}: any) {

  return (
    <View style={{position: "absolute", width: WIDTH, height: HEIGHT, zIndex: 2 }}>
      <Star style={{position: "absolute", left: WIDTH * 0.05, top: HEIGHT * 0.1, width: 50, height: 50}} duration={500} delay={1500} />
      <Star style={{position: "absolute", right: WIDTH * 0.2, top: HEIGHT * 0.1, width: 50, height: 50}} duration={700} delay={1000} />
      <Star style={{position: "absolute", left: WIDTH * 0.25, top: HEIGHT * 0.02, width: 50, height: 50}} duration={500} delay={1200} scale={2}/>
      <Star style={{position: "absolute", left: WIDTH / 2, top: HEIGHT * 0.3, width: 50, height: 50}} duration={500} delay={900}/>
      <Star style={{position: "absolute", left: WIDTH * 0.15, top: HEIGHT * 0.3, width: 50, height: 50}} duration={1000} delay={1000} />
      <Star style={{position: "absolute", right: 10, top: 10, width: 50, height: 50}} duration={600} />
      <Star style={{position: "absolute", left: WIDTH * 0.4, top: HEIGHT / 2, width: 50, height: 50}} duration={400} />
      <Star style={{position: "absolute", left: WIDTH * 0.04, top: HEIGHT * 0.6, width: 50, height: 50}} duration={500} delay={1600} />
      <Star style={{position: "absolute", right: WIDTH * 0.1,  top: HEIGHT * 0.7, width: 50, height: 50}} duration={300} delay={1300} />
      <Star style={{position: "absolute", right: WIDTH / 2,  top: HEIGHT * 0.8, width: 50, height: 50}} duration={900} delay={1000} />
      <Star style={{position: "absolute", right: WIDTH * 0.4,  top: HEIGHT * 0.5, width: 50, height: 50}} duration={600} delay={1000} />
    </View>
  );
}

export default SprinkerStar;