import React from "react";
import { AuthContext } from "@src/AuthProvider";
import { Pressable, Animated } from "react-native";

const AnimatedButton = Animated.createAnimatedComponent(Pressable);

const ImageButton05 = (props: any) => {
  const { handleClick } = React.useContext(AuthContext);
  const { children, style, onPress, disabled, scaleSize } = props;
  const animated = React.useRef(new Animated.Value(1)).current;

  const onPressIn = () => {
    Animated.timing(animated, {
      toValue: scaleSize || 0.9,
      duration: 100,
      useNativeDriver: false,
    }).start();
  };

  const onPressOut = () => {
    Animated.timing(animated, {
      toValue: 1,
      duration: 100,
      useNativeDriver: false,
    }).start();
  };

  const onClick = () => {
    onPress();
    handleClick();
  };

  return (
    <AnimatedButton
      onPress={onClick}
      disabled={disabled}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      style={[style, { transform: [{ scale: animated }] }]}
    >
      {children}
    </AnimatedButton>
  );
};

export default ImageButton05;
