// DUCKS pattern
import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
import _ from "lodash";
import moment from "moment";
import { deleteNotification, readNotification, updateMissions } from "utils/filter-helper";
import { MAavatars, MTierLevel } from "utils/mockdata";
import { ChangeScreen, ErrorValue, HourlyReward, Product, UserDetailValue } from "../types";

export interface InitialState {
  loading: boolean;
  lobbysuccess: boolean;
  error: ErrorValue;
  serverTime: number;
  jadeAmount: number;
  randomRewards: any[];
  missions: any[];
  missionEndDate: string;
  missionStatus: string;
  avatarTier: any;
  categoryId: number;
  gamesOrigin: any[];
  games: any[];
  products: Product[];
  isGoldGames: boolean;
  hourlyReward: HourlyReward;
  notifications: any[];
  featuredList: any[];
  changeScreen: ChangeScreen;

  claiming: boolean;
  claimingSuccess: boolean;
  claiming3hoursBonusSuccess: boolean;

  purchasing: boolean;
  purchaseSuccess: any;
  enableHotdeals: boolean;
}

export const initialState: InitialState = {
  loading: false,
  lobbysuccess: false,
  error: {} as ErrorValue,
  serverTime: 0,
  jadeAmount: 0,
  categoryId: -1,
  avatarTier: { displayName: "" },
  hourlyReward: {} as HourlyReward,
  notifications: [] as any,
  changeScreen: { screen: "main", gmode: "" } as ChangeScreen,
  gamesOrigin: [] as any,
  games: [] as any,
  products: [] as any,
  isGoldGames: false,
  featuredList: [] as any,
  missions: [] as any,
  missionEndDate: "",
  claiming: false,
  enableHotdeals: false,
  claimingSuccess: false,
  claiming3hoursBonusSuccess: false,
} as InitialState;

// Slice
export const lobbySlice = createSlice({
  name: "lobby",
  initialState,
  reducers: {
    lobbyRequest: (state) => {
      state.loading = true;
      state.lobbysuccess = false;
      state.error = {} as ErrorValue;
    },
    lobbySuccess: (state) => {
      state.error = {} as ErrorValue;
      state.loading = false;
      state.lobbysuccess = true;
    },
    lobbyFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    lobbyReset: () => {
      return initialState;
    },

    // SERVER TIME
    serverTime: (state, action) => {
      state.serverTime = action.payload;
    },
    
    jadeAmount: (state, action) => {
      state.jadeAmount = action.payload;
    },
    randomRewards: (state, action) => {
      state.randomRewards = action.payload;
    },
    missions: (state, action) => {
      state.missionEndDate = action.payload.endAt;
      state.missions = action.payload.userMissions;
      state.missionStatus = action.payload.status;
    },
    updateMissions: (state, action) => {
      state.missions = updateMissions(state.missions, action.payload);
    },
    avatarTier: (state, action) => {
      state.avatarTier = {...state.avatarTier, ...action.payload};
    },
    games: (state, action) => {
      if(action.payload.gamesOrigin){
        state.gamesOrigin = action.payload.gamesOrigin;
      }
      if(action.payload.featuredList){
        state.featuredList = action.payload.featuredList;
      }
      if(action.payload.transform){
        state.games = action.payload.transform;
      }
    },
    products: (state, action) => {
      state.products = action.payload;
    },
    goldGames: (state, action) => {
      state.games = action.payload.transform;
      state.isGoldGames = action.payload.isGoldGames;
    },
    hourlyReward: (state, action) => {
      state.hourlyReward = action.payload;
    },
    notifications: (state, action) => {
      state.notifications = _.chain(action.payload)
        .orderBy(["id"], ["asc"])
        .value();
    },
    updateNotifications: (state, action) => {
      if(action.payload.status === "READ"){
        state.notifications = readNotification(state.notifications, action.payload);
      }else if(action.payload.status === "DELETE"){
        state.notifications = deleteNotification(state.notifications, action.payload);
      }
    },
    changeScreen: (state, action) => {
      state.changeScreen = action.payload;
    },
    changeCategory: (state, action) => {
      state.categoryId = action.payload;
    },


    // EXCHANGE GOLD TO SILVER
    purchaseRequest: (state) => {
      state.purchasing = true;
      state.purchaseSuccess = {};
    },
    purchaseSuccess: (state, action) => {
      state.purchasing = false;
      state.purchaseSuccess = action.payload;
    },
    purchaseFailure: (state) => {
      state.purchasing = false;
      state.purchaseSuccess = {};
    },

    setEnableHotdeals: (state) => {
      state.enableHotdeals = true;
    },
  },
});

// Actions
export const lobbyActions = {
  lobbyRequest: lobbySlice.actions.lobbyRequest,
  lobbySuccess: lobbySlice.actions.lobbySuccess,
  lobbyFailure: lobbySlice.actions.lobbyFailure,
  lobbyReset: lobbySlice.actions.lobbyReset,

  changeCategory: createAction(
    `${lobbySlice.name}/changeCategory`,
    (params: number) => ({ payload: params })
  ),
  changeFavoriteGame: createAction(
    `${lobbySlice.name}/changeFavoriteGame`,
    (params: any) => ({ payload: params })
  ),

  // SERVER TIME
  serverTime: lobbySlice.actions.serverTime,
  jadeAmount: lobbySlice.actions.jadeAmount,
  randomRewards: lobbySlice.actions.randomRewards,
  missions: lobbySlice.actions.missions,
  updateMissions: lobbySlice.actions.updateMissions,
  avatarTier: lobbySlice.actions.avatarTier,
  games: lobbySlice.actions.games,
  products: lobbySlice.actions.products,
  goldGames: lobbySlice.actions.goldGames,
  hourlyReward: lobbySlice.actions.hourlyReward,
  notifications: lobbySlice.actions.notifications,
  updateNotifications: lobbySlice.actions.updateNotifications,
  changeScreen: lobbySlice.actions.changeScreen,

  // PURCHASE SHOP
  purchaseRequest: createAction(
    `${lobbySlice.name}/purchaseRequest`,
    (params: UserDetailValue) => ({
      payload: params,
    })
  ),
  purchaseSuccess: lobbySlice.actions.purchaseSuccess,
  purchaseFailure: lobbySlice.actions.purchaseFailure,

  //CHECK BALANCE
  balanceRequest: createAction(`${lobbySlice.name}/balanceRequest`),
  setEnableHotdeals: lobbySlice.actions.setEnableHotdeals,
};

// Selectors
export const selectedLobbyLoading = (state: RootState) => state.lobby.loading;
export const selectedLobbyFailed = (state: RootState) => state.lobby.error;
export const selectedLobbySuccess = (state: RootState) => state.lobby.lobbysuccess;
export const selectedJadeAmount = (state: RootState) => state.lobby.jadeAmount;
export const selectedRandomRewards = (state: RootState) => state.lobby.randomRewards;
export const selectedMissions = (state: RootState) => state.lobby.missions;
export const selectedGamesOrigin = (state: RootState) => state.lobby.gamesOrigin;
export const selectedGames = (state: RootState) => state.lobby.games;
export const selectedProducts = (state: RootState) => state.lobby.products;
export const selectedGoldGamesEnable = (state: RootState) => state.lobby.isGoldGames;
export const selectedFeaturedList = (state: RootState) => state.lobby.featuredList;
export const selectedNotifications = (state: RootState) => state.lobby.notifications;
export const selectedChangeScreen = (state: RootState) => state.lobby.changeScreen;
export const selectedClaiming = (state: RootState) => state.lobby.claiming;
export const selectedClaimingSuccess = (state: RootState) => state.lobby.claimingSuccess;
export const selectedClaiming3HoursBonusSuccess = (state: RootState) => state.lobby.claiming3hoursBonusSuccess;
export const selectedPurchaseLoad = (state: RootState) => state.lobby.purchasing;
export const selectedPurchaseData = (state: RootState) => state.lobby.purchaseSuccess;
export const selectedEnableHotdeals = (state: RootState) => state.lobby.enableHotdeals;
export const selectedCategoryId = (state: RootState) => state.lobby.categoryId;
export const selectedMissionEndDate = (state: RootState) => state.lobby.missionEndDate;

export const selectedMissionClaimed = createSelector(
  (state: RootState) => state.lobby.missionStatus,
  (state) => state === "REWARD_CLAIMED"
);

export const selectedMissionCompleted = createSelector(
  (state: RootState) => state.lobby.missionStatus,
  (state) => state === "COMPLETED"
);

export const selectedMissionReadyToClaim = createSelector(
  (state: any) => state.lobby.missions,
  (state: any) => state.lobby.missionStatus,
  (missions, missionStatus) => { 
    const result = missions.map((item: any) => {
      if (item.status === "REWARD_CLAIMED") {
        return true;
      } else {
        return null;
      }
    });

    return !result.includes(null) && missionStatus === "COMPLETED";
  }
);

export const selectedHourlyReward = createSelector(
  (state: RootState) => state.lobby.hourlyReward,
  (state) => {
    const start = moment(state.initialDate, "YYYY-MM-DD HH:mm:ss");
    const end = moment(state.claimDate, "YYYY-MM-DD HH:mm:ss");
    const seconds = moment.duration(end.diff(start)).asSeconds();

    return {
      id: state.id,
      timer: seconds > 0 ? seconds : 0,
    };
  }
);

export const selectedNotificationsUnread = createSelector(
  (state: RootState) => state.lobby.notifications,
  (state) => state.find((item: any) => item?.status === "UNREAD")
);

export const selectedActiveAvatarWB = createSelector(
  (state: RootState) => state.lobby.avatarTier,
  (state) => MAavatars[state.avatarID > 0 ? state.avatarID - 1 : 0].body,
);
export const selectedAvatarTier = createSelector(
  (state: any) => state.lobby.avatarTier,
  (state) => ({
    ...state,
    tierAvatar: MTierLevel[state.tierID - 1]?.tier_icon,
  })
);
export const selectedUserTierID = createSelector(
  (state: RootState) => state.lobby.avatarTier,
  (state) => state.tierID,
);

export const selectedServerTime = createSelector(
  (state: any) => state.lobby.serverTime,
  (state) => {
    if (state === 0) {
      return 0;
    }

    const today: Date = new Date(state);

    today.setDate(today.getDate() + 1);

    const date = moment(state).format("YYYY-MM-DD HH:mm:ss");
    const nextday = moment(today).format("YYYY-MM-DD 00:00:00");
    const seconds = moment.duration(moment(nextday).diff(date)).asSeconds();

    return seconds;
  }
);

export const selectedMissionHasKeys = createSelector(
  (state: any) => state.lobby.missions,
  (state) => {
    const result = state.map((item: any) => {
      if (item.hasKey) {
        return "HAS_KEY";
      } else if (item.completedDate) {
        return true;
      } else {
        return null;
      }
    });

    return result.includes("HAS_KEY");
  }
);

// Reducer
export default lobbySlice.reducer;
