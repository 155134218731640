import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedChangeScreen } from "@src/ducks/slices/lobby.slice";
import React from "react";
import { useWindowDimensions } from "react-native";
import { Opacity } from "utils/animation";
import useStyles from "./styles.css";

interface AuthProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  baseWidth: number;
  baseHeight: number;
}

export const ResponsiveContext = React.createContext<ContextValue>(
  {
    baseWidth: 0,
    baseHeight: 0
  } as ContextValue
);

const NavProvider = ({ children }: AuthProviderProps) => {
  const styles = useStyles();
  const { width } = useWindowDimensions();
  const { screen } = useAppSelector(selectedChangeScreen);
  const baseHeight = React.useMemo(() => {
    return width * 0.1005;
  },[width]);

  return (
    <ResponsiveContext.Provider value={{ baseWidth: width, baseHeight }}>
      <Opacity toValue={screen === "gold-shop" ? 0 : 1} style={[styles.vw_botnav, { height: baseHeight}]}>
        {children}
      </Opacity>
    </ResponsiveContext.Provider>
  );
};

export default NavProvider;
