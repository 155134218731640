import images from "@assets/images";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedGModeURL } from "@src/ducks/slices/gmode.slice";
import { ImageButton06 } from "components/button";
import React, { useState } from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const Portrait = () => {
  const styles = useStyles();
  const [visible, setVisible] = useState(false);
  const gameLink = useAppSelector(selectedGModeURL);
  const onFullScreen = () => document.body.requestFullscreen();

  React.useEffect(() => {
    function readDeviceOrientation() {
      if (typeof window.orientation !== "undefined") {
        if (Math.abs(window.orientation) === 90) {
          setVisible(false);
        } else {
          setVisible(true);
        }
      }
    }

    window.onorientationchange = readDeviceOrientation;

    window.onload = readDeviceOrientation;

    readDeviceOrientation();
  }, []);

  if (!visible || gameLink) {
    return null;
  }

  return (
    <View style={[styles.container]}>
      <Image
        source={{uri: images["gamezone-logo"]}}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <Text style={styles.t_loading}>Please rotate your device</Text>
      <Image
        source={{uri: images.phone_rotate}}
        style={styles.p_rotate_style}
        resizeMode="stretch"
      />
      <ImageButton06
        onPress={onFullScreen}
        style={[styles.btnLogin]}
        label="PLAY NOW"
        source={images["btn-small"]}
      />
    </View>
  );
};

export default React.memo(Portrait);
