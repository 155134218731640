import images from "@assets/images";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const TierLevelItem = (props: any) => {
  const styles = useStyles();

  return (
    <View style={[styles.v_tier_level]}>
      <Image
        style={[styles.i_tier_frame]}
        source={{uri: images.tier_frame}}
        resizeMode="stretch"
      />
      <Text style={[styles.t_tier_label, 
        { fontSize: props.baseWidth * 0.014}]}>{props.title}</Text>
      <Image
        style={[styles.i_tier_trophy]}
        source={props.source}
        resizeMode="stretch"
      />
      <Text style={[styles.t_game_access, 
        { fontSize: props.baseWidth * 0.01}]} >GAME ACCESS</Text>
      <Text style={[styles.t_tier_notes, 
        { fontSize: props.baseWidth * 0.009}]} >{props.access}</Text>
      <Text style={[styles.t_game_access, 
        { fontSize: props.baseWidth * 0.01, marginTop: "1.5%"}]} >BONUS</Text>
      <Text style={[styles.t_tier_notes, 
        { fontSize: props.baseWidth * 0.009}]} >{props.bonus}</Text>
    </View>
  );
};

export default TierLevelItem;
