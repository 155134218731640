import { CDN_URL } from "@env";
import { HighRollerTypes } from "./image-types/high-roller-types";

const HighRollerAssets: HighRollerTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/high-roller/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/high-roller/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/high-roller/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/high-roller/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/high-roller/images/img_4_$.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/high-roller/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/high-roller/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/high-roller/images/img_7.png",
  "img_8": CDN_URL + "/image-assets/animation-assets/high-roller/images/img_8.png",
  "img_9": CDN_URL + "/image-assets/animation-assets/high-roller/images/img_9.png",
  "seq_0_0": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_0.png",
  "seq_0_1": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_1.png",
  "seq_0_2": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_2.png",
  "seq_0_3": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_3.png",
  "seq_0_4": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_4.png",
  "seq_0_5": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_5.png",
  "seq_0_6": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_6.png",
  "seq_0_7": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_7.png",
  "seq_0_8": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_8.png",
  "seq_0_9": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_9.png",
  "seq_0_10": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_10.png",
  "seq_0_11": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_11.png",
  "seq_0_12": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_12.png",
  "seq_0_13": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_13.png",
  "seq_0_14": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_0_14.png",
  "seq_1_0": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_0.png",
  "seq_1_1": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_1.png",
  "seq_1_2": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_2.png",
  "seq_1_3": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_3.png",
  "seq_1_4": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_4.png",
  "seq_1_5": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_5.png",
  "seq_1_6": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_6.png",
  "seq_1_7": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_7.png",
  "seq_1_8": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_8.png",
  "seq_1_9": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_9.png",
  "seq_1_10": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_10.png",
  "seq_1_11": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_11.png",
  "seq_1_12": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_12.png",
  "seq_1_13": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_13.png",
  "seq_1_14": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_14.png",
  "seq_1_15": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_15.png",
  "seq_1_16": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_16.png",
  "seq_1_17": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_17.png",
  "seq_1_18": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_18.png",
  "seq_1_19": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_19.png",
  "seq_1_20": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_20.png",
  "seq_1_21": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_21.png",
  "seq_1_22": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_22.png",
  "seq_1_23": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_23.png",
  "seq_1_24": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_24.png",
  "seq_1_25": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_25.png",
  "seq_1_26": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_26.png",
  "seq_1_27": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_27.png",
  "seq_1_28": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_28.png",
  "seq_1_29": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_29.png",
  "seq_1_30": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_30.png",
  "seq_1_31": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_31.png",
  "seq_1_32": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_32.png",
  "seq_1_33": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_33.png",
  "seq_1_34": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_34.png",
  "seq_1_35": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_35.png",
  "seq_1_36": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_36.png",
  "seq_1_37": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_37.png",
  "seq_1_38": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_38.png",
  "seq_1_39": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_39.png",
  "seq_1_40": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_40.png",
  "seq_1_41": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_41.png",
  "seq_1_42": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_42.png",
  "seq_1_43": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_43.png",
  "seq_1_44": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_44.png",
  "seq_1_45": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_45.png",
  "seq_1_46": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_46.png",
  "seq_1_47": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_47.png",
  "seq_1_48": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_48.png",
  "seq_1_49": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_49.png",
  "seq_1_50": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_50.png",
  "seq_1_51": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_51.png",
  "seq_1_52": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_52.png",
  "seq_1_53": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_53.png",
  "seq_1_54": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_54.png",
  "seq_1_55": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_55.png",
  "seq_1_56": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_56.png",
  "seq_1_57": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_57.png",
  "seq_1_58": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_58.png",
  "seq_1_59": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_59.png",
  "seq_1_60": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_60.png",
  "seq_1_61": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_61.png",
  "seq_1_62": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_62.png",
  "seq_1_63": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_63.png",
  "seq_1_64": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_64.png",
  "seq_1_65": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_65.png",
  "seq_1_66": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_66.png",
  "seq_1_67": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_67.png",
  "seq_1_68": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_68.png",
  "seq_1_69": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_69.png",
  "seq_1_70": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_70.png",
  "seq_1_71": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_71.png",
  "seq_1_72": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_72.png",
  "seq_1_73": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_73.png",
  "seq_1_74": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_74.png",
  "seq_1_75": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_75.png",
  "seq_1_76": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_76.png",
  "seq_1_77": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_77.png",
  "seq_1_78": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_78.png",
  "seq_1_79": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_79.png",
  "seq_1_80": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_80.png",
  "seq_1_81": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_81.png",
  "seq_1_82": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_82.png",
  "seq_1_83": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_83.png",
  "seq_1_84": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_84.png",
  "seq_1_85": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_85.png",
  "seq_1_86": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_86.png",
  "seq_1_87": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_87.png",
  "seq_1_88": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_88.png",
  "seq_1_89": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_89.png",
  "seq_1_90": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_90.png",
  "seq_1_91": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_91.png",
  "seq_1_92": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_92.png",
  "seq_1_93": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_93.png",
  "seq_1_94": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_94.png",
  "seq_1_95": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_95.png",
  "seq_1_96": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_96.png",
  "seq_1_97": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_97.png",
  "seq_1_98": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_98.png",
  "seq_1_99": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_99.png",
  "seq_1_100": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_100.png",
  "seq_1_101": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_101.png",
  "seq_1_102": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_102.png",
  "seq_1_103": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_103.png",
  "seq_1_104": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_104.png",
  "seq_1_105": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_105.png",
  "seq_1_106": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_106.png",
  "seq_1_107": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_107.png",
  "seq_1_108": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_108.png",
  "seq_1_109": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_109.png",
  "seq_1_110": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_110.png",
  "seq_1_111": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_111.png",
  "seq_1_112": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_112.png",
  "seq_1_113": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_113.png",
  "seq_1_114": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_114.png",
  "seq_1_115": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_115.png",
  "seq_1_116": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_116.png",
  "seq_1_117": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_117.png",
  "seq_1_118": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_118.png",
  "seq_1_119": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_119.png",
  "seq_1_120": CDN_URL + "/image-assets/animation-assets/high-roller/images/seq_1_120.png",
};

export default HighRollerAssets;
