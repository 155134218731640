import { Asset } from "expo-asset";

const audio: any = {
  whoosh: require("../../assets/audio/bg-sound.mp3"),
  claim: require("../../assets/audio/claim.wav"),
  click: require("../../assets/audio/click.mpeg"),
  audience_applause: require("../../assets/audio/audience_applause.wav"),
  random_numbers: require("../../assets/audio/random-numbers.mp3"),
  reward_sound: require("../../assets/audio/reward-sound.mp3"),
  ref_wistle: require("../../assets/audio/ref_wistle.mp3"),
  "tier-level-up": require("../../assets/audio/tier-level-up.mp3"),

  // GOLDEN BALL
  "golden-ball-intro": require("../../assets/audio/golden-ball/golden-ball-intro.mp3"),
  "golden-ball-open": require("../../assets/audio/golden-ball/golden-ball-open.mp3"),

  // POPUPS
  "whistle-warrior": require("../../assets/audio/whistle-warrior.mp3"),
  "high-roller": require("../../assets/audio/high-roller.mp3"),
  "hot-deals": require("../../assets/audio/hot-deals.mp3"),
  "starter-pack": require("../../assets/audio/starter-pack.mp3"),
  "weekly-booster": require("../../assets/audio/weekly-booster.mp3"),
  "tgif": require("../../assets/audio/tgif.mp3"),
  "mission-rewards": require("../../assets/audio/mission-rewards.mp3"),

  // DAILY BONUS
  shuffle_sfx: require("../../assets/audio/daily-reward/shuffle_sfx.mp3"),
  congrats_sfx: require("../../assets/audio/daily-reward/congrats_sfx.mp3"),


};

// image preloading
export const audioAssets = Object.keys(audio).map((key) =>
  Asset.fromModule(audio[key]).downloadAsync()
);

export default audio;
