import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { View } from "react-native";
import useStyles from "./styles.css";
import _ from "lodash";

function DatePicker01(props: any) {
  const styles = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <View style={[styles.container, props.style]}>
        <DatePicker
          sx={{width: "100%", height: "100%", alignItems: "center", justifyContent: "center"}}
          format={props.format}
          disablePast={props.disablePast}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: "outlined",
              error: !_.isEmpty(props.error),
              InputProps: {
                sx: [styles.inputSx, props.textField],
              },
            },
          }}
          onChange={props.onChange}
        />
      </View>
    </LocalizationProvider>
  );
}

export default DatePicker01;
