import React, { useState } from "react";
import TierInfoMore from "./TierInfoMore";
import TierLevelReward from "./TierLevelReward";

export default () => {
  const [viewmore, setViewmore] = useState(false);

  return viewmore ? (
    <TierInfoMore />
  ) : (
    <TierLevelReward onPress={() => setViewmore(true)} />
  );
};
