import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { verticalScale } from "react-native-size-matters";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 6,
      alignItems: "center",
      justifyContent: "center",
      ...StyleSheet.absoluteFillObject,
      backgroundColor: colors.translucent,
    },
    center_view: {
      width: verticalScale(300),
      height: verticalScale(200),
      justifyContent: "center",
      borderRadius: 20,
      backgroundColor: colors.white,
    },
    v_top: {width: "100%", height: "30%", alignItems: "center", justifyContent: "center"},
    image_logo: { width: verticalScale(200), height: verticalScale(40)},
    v_bottom: {width: "100%", height: "70%", alignItems: "center", justifyContent: "center",
      backgroundColor: colors.blue1, borderBottomLeftRadius: 20, borderBottomRightRadius: 20},
    t_label: {fontFamily: "Roboto-Bold", fontSize: 30, color: colors.white, textAlign: "center"},
    v_circle: {width: 130, height: 130, borderRadius: 130/2, 
      alignItems: "center", justifyContent: "center",
      borderWidth: 2, borderColor: colors.white, marginTop: 20},
    btn_close: {
      zIndex: 4,
      width: "6%",
      height: "10%",
      top: "3%",
      right: "3%",
      position: "absolute",
    },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      center_view: {
        width: verticalScale(400),
        height: verticalScale(300),
      },
      v_circle: {width: 100, height: 100, borderRadius: 100/2, marginTop: 15},
      t_label: { fontSize: 24 }, 
      image_logo: { width: verticalScale(300), height: verticalScale(60)},
    },
  }
);

export default useStyles;
