import images from "@assets/images";
import { useRoute } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { useForgotService } from "@src/ducks/hooks";
import { ImageButton06 } from "components/button";
import { TextInput01 } from "components/textinput";
import _ from "lodash";
import React, { useState } from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const ResetPassword = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const code = route?.params?.code;
  const userId = route?.params?.userId;
  const [error, setError] = useState<any>({});
  const [newPassword, setNewPassword] = useState("");
  const {isLoading, onResetPassword} = useForgotService();
  const [confirmPassword, setConfirmPassword] = useState("");
  const { baseWidth, maxHeight } = React.useContext(DimensionContext);
  
  const onSubmit = () => {
    // REGEX /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/;
    const newErr: any = {};
    if (!newPassword) {
      newErr.newPassword = "Required.";
    } else if (newPassword.match(/^(.{0,7})$/)) {
      newErr.newPassword = "At least 8 character long";
    } else if (newPassword.match(/^([^0-9]*)$/)) {
      newErr.newPassword = "Contains at least one digit";
    } else if (newPassword.match(/^([^A-Z]*)$/)) {
      newErr.newPassword = "Contains at least one uppercase letter";
    } else if (newPassword.match(/^([^a-z]*)$/)) {
      newErr.newPassword = "Contains at least one lowercase letter";
    } else if (newPassword.match(/^([a-zA-Z0-9]*)$/)) {
      newErr.newPassword = "Contains at least one special character";
    } 
    else if (!confirmPassword) {
      newErr.confirmPassword = "Required.";
    } else if (newPassword !== confirmPassword) {
      newErr.confirmPassword = "Not match.";
    }

    setError(newErr);

    if (_.isEmpty(newErr)) {
      const params = {
        code,
        userId,
        newPassword,
      };

      onResetPassword(params);
    }
  };

  React.useEffect(() => {
    setError({});
  }, [newPassword, confirmPassword]);

  return (
    <View style={[styles.bodyStyle, { height: maxHeight * 0.5}]}>
      <Image
        source={{uri: images.bg_transparent}}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <Image
        source={{uri: images["gamezone-logo-horizontal"]}}
        style={styles.smallLogoContainer}
        resizeMode="stretch"
      />
      <View style={styles.v_center_reset}>
        <View style={[styles.viewForgotStyle]}>
          <Image
            resizeMode="contain"
            source={{uri: images["text-reset-password"]}}
            style={styles.forgotTextStyle}
          />
        </View>
        <Text style={[styles.txtEmailNote, { fontSize: baseWidth * 0.013 }]}>
            Strong passwords include numbers, letters and punctuation marks
        </Text>
        <TextInput01
          maxLength={64}
          value={newPassword}
          placeholder="New Password"
          secureTextEntry={false}
          error={error.newPassword}
          onChangeText={setNewPassword}
          disableFullscreenUI={true}
          inputStyle={[styles.textAlign, { fontSize: baseWidth * 0.013 }]}
          containerStyle={styles.ti_reset}
        />
        <TextInput01
          maxLength={64}
          value={confirmPassword}
          placeholder="Confirm New Password"
          secureTextEntry={false}
          onChangeText={setConfirmPassword}
          disableFullscreenUI={true}
          error={error.confirmPassword}
          inputStyle={[styles.textAlign, { fontSize: baseWidth * 0.013 }]}
          containerStyle={styles.ti_reset}
        />
        <ImageButton06
          label="SUBMIT"
          onPress={onSubmit}
          isLoading={isLoading}
          style={[styles.btn_reset_password]}
          source={images["btn-big"]}
          labelStyle={{fontSize: baseWidth * 0.013}}
        />
      </View>
    </View>
  );
};

export default ResetPassword;
