import { API_BASE_URL } from "@env";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAuthToken } from "@src/ducks/slices/auth.slice";
import { useEffect, useState } from "react";

let resetTimer: any = null;
const inEndless401Loop = (lastErrors: number[]) =>
  lastErrors[0] === 401 && lastErrors[1] === 401;

export const useFetchGet = (
  routes: string,
  options: any = null,
  initialData = null
) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState(initialData);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [lastErrors, setLastErrors] = useState<any>([]);
  const accessToken = useAppSelector(selectedAuthToken);

  const runRequestCycle = () => {
    async function request() {
      try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}${routes}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          signal: controller.signal,
          ...options,
        });

        if (!response.ok) {
          throw response;
        }

        const body = await response.json();
        
        setData(body);
        setError(null);
        setLastErrors([]);
      } catch (err: any) {
        setError(err);
        setLastErrors([err?.code, ...lastErrors]);
      } finally {
        setLoading(false);
        setRefresh(false);
      }
    }
    const controller = new AbortController();

    // dont get into an endless 401 loop
    if (inEndless401Loop(lastErrors)) {
      if (resetTimer == null) {
        resetTimer = setTimeout(() => {
          setLastErrors([]);
          resetTimer = null;
        }, 60000);
      }
    } else {
      accessToken && request();
    }
    return controller;
  };

  useEffect(() => {
    const controller = runRequestCycle();
    return () => {
      controller.abort();
    };
  }, [routes]);

  useEffect(() => {
    if (!refresh) {
      return;
    }
    const controller = runRequestCycle();
    return () => {
      controller.abort();
    };
  }, [refresh]);

  if (inEndless401Loop(lastErrors)) {
    return [false, null, { code: 401, message: "Failed to connect" }];
  }

  return {
    loading,
    data,
    error,
    refresh: () => setRefresh(true),
  } as any;
};
